<template>
  <v-container class="pa-0 create-device-container">
    <v-row>
      <v-col class="create-device-col">
        <div class="breadcrumbs-container breadcrumbs-container-create-device">
          <v-row style="justify-content: center; text-align: center">
            <h1>{{ $t('device.form.new__device') }}</h1>
          </v-row>
          <v-row class="breadcrumbs" style="justify-content: center">
            <v-breadcrumbs :items="items" class="pl-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <div class="container-create-device" style="max-width: 80%; margin-left: auto !important; margin-right: auto !important">
      <h2 class="d-flex justify-start my-2">
        {{ $t('user.form.details') }}
      </h2>

      <DeviceDataForm ref="deviceData"></DeviceDataForm>

      <v-row class="buttoms-edit-user-mobile d-flex justify-center mt-10">
        <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="createDevice">
          <v-icon class="mr-1">mdi-content-save</v-icon>{{ $t('general.form.save') }}
        </v-btn>

        <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
          <v-icon class="white--text mr-1">mdi-exit-to-app</v-icon>
          <span class="white--text">{{ $t('general.form.exit') }}</span>
        </v-btn>
      </v-row>

      <ErrorDialog
        :isActive="errorDialog"
        v-bind:tittleText="$t('general.dialog.error.title')"
        :messageText="errorText"
        v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
        v-on:accept-dialog="errorDialog = false">
      </ErrorDialog>
    </div>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import DeviceDataForm from '../../../../components/forms/deviceDataForm/deviceDataForm.vue';
import { createDevice } from '../../../../viewModels/adminViewModel.js';
import { CreateDeviceException } from '../../../../server/petitions/devices/createDevice';
import ErrorDialog from '../../../../components/errorDialog.vue';
import { getConfig } from '../../../../viewModels/reportsViewModel';

export default {
  components: {
    DeviceDataForm,
    ErrorDialog,
  },
  data: () => ({
    errorDialog: false,
    errorText: '',
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Dispositivos',
        disabled: false,
        href: '/admin/devices',
      },
      {
        text: 'Crear dispositivo',
        disabled: true,
        href: 'admin/devices/create',
      },
    ],
  }),
  methods: {
    goBack: function () {
      router.push({ name: 'devices' });
    },
    createDocument: async function (device) {
      let payload = {
        IdentDevice: Number(device.serialNumber),
        AesIv: device.aesIv,
        AesKey: device.aesKey,
        StorePeriod: 1,
        IdentPlate: '',
      };

      try {
        await getConfig(payload);

        this.txtLoading = false;
      } catch (error) {
        throw { error, date: new Date() };
      }
    },
    createDevice: async function () {
      try {
        var deviceDataForm = this.$refs.deviceData;

        if (deviceDataForm.validate()) {
          let serial_number = Number(deviceDataForm.serialNumber);
          let model = deviceDataForm.selectedModel;
          let submodel = deviceDataForm.selectedSubmodel;
          let distributor = deviceDataForm.selectedDistributor;
          let workshop = deviceDataForm.selectedWorkshop;
          let company = deviceDataForm.selectedCompany;
          let password = deviceDataForm.password;
          let iccid = deviceDataForm.iccid;
          let aes = {
            iv: deviceDataForm.aesIv,
            key: deviceDataForm.aesKey,
          };
          let certificate_date = deviceDataForm.certificateDate;
          let third_party_services = {
            webfleet: {
              type: 'webfleet',
              account: deviceDataForm.webfleetAccount,
              username: deviceDataForm.webfleetUsername,
              password: deviceDataForm.webfleetPassword,
              object_number: deviceDataForm.webfleetObjectNumber,
              object_uid: deviceDataForm.webfleetObjectUid,
            },
            carrier: {
              api_key: deviceDataForm.carrierApiKey,
              tru_serial_number: deviceDataForm.carrierTruSerialNumber,
              asset_id: deviceDataForm.carrierAssetId,
            },
          };

          let notes = deviceDataForm.notes;
          let gps_enabled = deviceDataForm.isGPSEnabled;
          let enabled = deviceDataForm.enabled;
          let users = deviceDataForm.users;
          let linked_to = deviceDataForm.linkedTo;
          let vehicleType = deviceDataForm.vehicleType;

          const payload = {
            serial_number,
            model,
            submodel,
            distributor,
            workshop,
            company,
            iccid,
            password,
            aes,
            certificate_date,
            third_party_services,
            notes,
            gps_enabled,
            enabled,
            users,
            linked_to,
            vehicleType,
          };
          await createDevice(payload);

          if (model == 'apache') this.createDocument(deviceDataForm);
          this.goBack();
        }
      } catch (e) {
        switch (e.code) {
          case CreateDeviceException.unknownError:
          case CreateDeviceException.incorrectParameters:
          case CreateDeviceException.invalidToken:
          case CreateDeviceException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
  },
};
</script>
