<template>
  <v-form ref="beaconForm" v-model="formValid" lazy-validation>
    <v-autocomplete
      :items="usersToChoose"
      @change="addUser"
      :item-text="getItemText"
      return-object
      outlined
      autofocus
      v-bind:label="$t('device.form.add_user')"
      class="device-search rounded-lg mt-4 mb-0">
    </v-autocomplete>

    <div id="device-data-form-container">
      <div class="device-data-form-table">
        <v-data-table
          :headers="usersTableHeader"
          :items="usersTableData"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          :loading="isTableLoading"
          class="elevation-1 ma-4 mt-0"
          @click:row="editUser"
          @contextmenu:row="editUserCtrl"
          style="overflow: auto; min-height: 100px; max-height: 400px">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </div>

      <div class="expansion-panel-edit-data">
        <v-expansion-panels class="card-data expansion-panels">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>
                    {{ $t('device.title') }}
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div id="container-edit-device">
                <v-row class="edit-data-user edit-data-device-serie">
                  <v-col class="edit-data-left">
                    <label class="font-weight-black float-left">{{ $t('general.form.mac_address') }}* </label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field
                      v-if="!beaconData.mac_address"
                      dense
                      outlined
                      class="rounded-lg"
                      type="text"
                      v-model="macAddress"
                      :rules="macAddressRules"></v-text-field>
                    <div v-if="beaconData.mac_address" class="font-weight-bold">
                      {{ beaconData.mac_address }}
                    </div>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('general.form.beacon_alias') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field dense outlined class="rounded-lg" type="text" v-model="alias"></v-text-field>
                  </v-col>
                </v-row>
                <!-- --------------ESTO ES PARA EL MODELO----------- -->
                <!-- <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('device.atribute.model') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-select
                      :items="getModels"
                      v-model="selectedModel"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      class="rounded-lg"
                      required></v-select>
                  </v-col>
                </v-row> -->

                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('general.form.categories') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-select
                      :items="getCategories"
                      v-model="selectedCategory"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      class="rounded-lg"
                      required></v-select>
                  </v-col>
                </v-row>
                <v-row class="edit-data-user edit-data-device">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">
                      {{ $t('device.atribute.notes') }}
                    </label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field dense outlined class="rounded-lg" type="text" v-model="notes"></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </v-form>
</template>

<script>
import router from '../../../plugins/router/index';
import { getAllUsers, getBeaconUsers } from '../../../viewModels/adminViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  props: {
    beaconData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    userIds: [],
    theme: ls.get('theme') ?? 'ligth',
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    formValid: false,
    isTableLoading: true,
    users: [],
    usersToRemoveBeacon: [],
    macAddress: '',
    alias: '',
    // selectedModel: '', ---------ESTO ES PARA EL MODELO------
    selectedCategory: '',
    notes: '',
    usersToChoose: [],
    usersTableData: [],
  }),
  methods: {
    getItemText(item) {
      return `${item.name} ~ ${item.email} ~ ${item.devices?.length || 0}`;
    },

    editUser: function (e) {
      router.push({
        name: 'editUser',
        params: { userId: e._id, userData: e },
      });
    },
    editUserCtrl: async function (e, i) {
      e.preventDefault();
      let routeData = router.resolve({ name: 'editUser', params: { userId: i.item._id, userData: i.item } });
      window.open(routeData.href, '_blank');
    },
    addUser: function (e) {
      if (this.users?.includes(e?._id)) this.$emit('user-repeat');

      if (e && !this.users.includes(e?._id)) {
        this.userIds.push(e?._id);
        this.usersTableData.push(e);
        this.users.push(e?._id);

        let indexToDelete = this.usersToRemoveBeacon.indexOf(e?._id);
        this.usersToRemoveBeacon.splice(indexToDelete, 1);
        this.$emit('user-no-repeat');
      }
    },
    deleteRow: function (item) {
      let itemId = item.userId ?? item._id;
      this.usersToRemoveBeacon.push(itemId);

      //DELETE IN BEACONS
      let indexToDeleteBeacons = this.users.indexOf(itemId);
      this.users.splice(indexToDeleteBeacons, 1);

      //DELETE IN BEACONS-TABLE-DATA
      let indexToDelete = this.usersTableData.indexOf(item);
      this.usersTableData.splice(indexToDelete, 1);

      // ELIMINA LOS USUARIOS VINCULADOS EN THIS.USERS
      this.users = this.users.filter((userId) => {
        return !this.usersTableData.some((user) => user._id === userId);
      });

      this.$emit('deleted-correctly');
    },
    validate: function () {
      return this.$refs.beaconForm.validate();
    },
  },

  mounted: async function () {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.usersToChoose = await getAllUsers();

    this.usersTableData = await getBeaconUsers(this.beaconData._id);
    this.alias = this.beaconData.alias;
    this.selectedCategory = this.beaconData.category ?? 'tractorHead';
    this.notes = this.beaconData.notes;
    this.macAddress = this.beaconData.mac_address;

    for (let user of this.usersTableData) {
      this.users.push(user._id);
    }

    this.isTableLoading = false;
  },

  computed: {
    //----- ESTO ES PARA EL MODELO--------
    // getModels() {
    //   return [
    //     { name: ` ${this.$t('admin.menu.beacon')}-1`, value: 'modelOne' },
    //     { name: ` ${this.$t('admin.menu.beacon')}-2`, value: 'modelTwo' },
    //     { name: ` ${this.$t('admin.menu.beacon')}-3`, value: 'modelThree' },
    //     { name: ` ${this.$t('admin.menu.beacon')}-4`, value: 'modelFour' },
    //   ];
    // },
    getCategories() {
      return [
        { name: ` ${this.$t('admin.menu.tractorhead')}`, value: 'tractorHead' },
        { name: ` ${this.$t('admin.menu.driver')}`, value: 'driver' },
        { name: ` ${this.$t('admin.menu.merchandise')}`, value: 'merchandise' },
      ];
    },

    usersTableHeader() {
      return [
        { text: this.$t('user.atribute.name'), value: 'name' },
        { text: this.$t('user.atribute.email'), value: 'email' },
        { text: this.$t('user.atribute.roles.roles'), value: 'type' },
        { text: '', value: 'actions' },
      ];
    },

    macAddressRules() {
      return [
        (value) => !!value || this.$t('general.error.empty_value'),
        (value) => /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/.test(value) || this.$t('general.error.invalid_mac'),
      ];
    },
  },
};
</script>
