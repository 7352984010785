<template>
  <v-container v-if="!isMobile" :key="$route.fullPath">
    <v-row class="edit-device-firts-container">
      <v-col class="header-title pb-0" cols="9">
        <div class="breadcrumbs-container">
          <v-row>
            <h1>
              {{ $t('device.name') + ' ' + deviceData.device_sn }}
            </h1>
          </v-row>
          <v-row class="breadcrumbs mb-0">
            <v-breadcrumbs :items="items" class="pl-0 pb-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row class="edit-device-firts-container">
      <v-col class="pt-0">
        <DeviceDataForm
          class="edit-device-data-form"
          ref="deviceDataForm"
          :deviceData="deviceData"
          @setDevices="setDevices"
          @deleted-correctly="deletedCorrectly"
          @user-repeat="userRepeat"
          @user-no-repeat="userNoRepeat">
        </DeviceDataForm>

        <v-row class="edit-device-buttons d-flex justify-center mt-10">
          <v-btn color="primary" class="d-flex justify-space-between mx-15" @click="save">
            <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
          </v-btn>
          <v-btn color="red" class="d-flex justify-space-between mx-15" @click="goBack">
            <v-icon class="white--text">mdi-exit-to-app</v-icon>
            <span class="white--text">{{ $t('general.form.exit') }}</span>
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="3" class="device-actions pt-0">
        <v-card elevation="3" class="card-actions ma-3">
          <v-container class="card-actions ma-3">
            <v-row class="d-flex flex-column justify-space-around mb-1">
              <v-btn @click="createDocument" class="ma-3 mb-0 d-flex justify-center" color="primary" :disabled="!isApacheModel">
                <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="mr-2" />
                {{ $t('device.actions.download_cfg') }}
              </v-btn>
              <v-btn @click="certificate" class="ma-3 mb-0 d-flex justify-center" color="green">
                <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="mr-2" />
                Certificados
              </v-btn>
              <v-btn @click="openApacheBase" class="ma-3 mb-0 d-flex justify-center white--text" color="#20c997">
                <font-awesome-icon icon="fa-solid fa-up-right-from-square" class="mr-2" />
                {{ $t('device.actions.open_in_apachebase') }}
              </v-btn>
              <v-btn class="ma-3 mb-0 d-flex justify-center" color="error" disabled>
                <font-awesome-icon icon="fa-solid fa-trash" class="mr-2" />
                {{ $t('device.actions.delete_device') }}
              </v-btn>
            </v-row>
          </v-container></v-card
        >

        <v-expansion-panels class="card-frame-frigo expansion-panels mb-2" v-if="deviceData.last_frames.frame_frigo">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>
                    {{ $t('device.last_frames.frame_frigo.name') }}
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div class="expansion-panel-body">
                <div class="atribute-date expansion-panel-info">
                  <span class="expansion-panel-info-text text-tooltip" style="color: #b41c5c">
                    {{ getFrigoTimestampTooltip }}
                  </span>
                </div>

                <v-divider class="my-2"></v-divider>

                <div
                  v-if="deviceData.last_frames.frame_frigo.probes_temperature.p1"
                  class="frigo-probe1 atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_frigo.probe1') }}:</p>
                  <span class="expansion-panel-info-text">
                    {{
                      !isNaN(deviceData.last_frames.frame_frigo.probes_temperature.p1)
                        ? deviceData.last_frames.frame_frigo.probes_temperature.p1.toFixed(1)
                        : '---'
                    }}{{ deviceData.device_data_from_frame.probes_type.p1 == 'hum' ? '% RH' : 'ºC' }}
                  </span>
                </div>

                <div
                  v-if="deviceData.last_frames.frame_frigo.probes_temperature.p2"
                  class="frigo-probe2 atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_frigo.probe2') }}:</p>
                  <span class="expansion-panel-info-text">
                    {{
                      !isNaN(deviceData.last_frames.frame_frigo.probes_temperature.p2)
                        ? deviceData.last_frames.frame_frigo.probes_temperature.p2.toFixed(1)
                        : '---'
                    }}{{ deviceData.device_data_from_frame.probes_type.p2 == 'hum' ? '% RH' : 'ºC' }}
                  </span>
                </div>
                <div
                  v-if="deviceData.last_frames.frame_frigo.probes_temperature.p3"
                  class="probe-sonda3 atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">Sonda 3:</p>
                  <span class="expansion-panel-info-text">
                    {{
                      !isNaN(deviceData.last_frames.frame_frigo.probes_temperature.p3)
                        ? deviceData.last_frames.frame_frigo.probes_temperature.p3.toFixed(1)
                        : '---'
                    }}{{ deviceData.device_data_from_frame.probes_type.p3 == 'hum' ? '% RH' : 'ºC' }}
                  </span>
                </div>
                <div
                  v-if="deviceData.last_frames.frame_frigo.probes_temperature.p4"
                  class="probe-sonda4 atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">Sonda 4:</p>
                  <span class="expansion-panel-info-text">
                    {{
                      !isNaN(deviceData.last_frames.frame_frigo.probes_temperature.p4)
                        ? deviceData.last_frames.frame_frigo.probes_temperature.p4.toFixed(1)
                        : '---'
                    }}{{ deviceData.device_data_from_frame.probes_type.p4 == 'hum' ? '% RH' : 'ºC' }}
                  </span>
                </div>
                <div class="atribute-carplate atribute-date expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.atribute.carplate') }}:</p>
                  <span class="expansion-panel-info-text">
                    {{
                      deviceData.device_data_from_frame.carplate
                        ? deviceData.device_data_from_frame.carplate
                        : deviceData.device_sn
                    }}
                  </span>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="card-frame-gps expansion-panels mb-2" v-if="deviceData.last_frames.frame_gps">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>
                    {{ $t('device.last_frames.frame_gps.name') }}
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div class="expansion-panel-body">
                <div class="expansion-panel-info">
                  <span class="expansion-panel-info-text text-tooltip" style="color: #b41c5c">{{ getGpsTimestampTooltip }}</span>
                </div>
                <v-divider class="my-2"></v-divider>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.position') }}:</p>
                  <span class="expansion-panel-info-text">
                    <a
                      class="text-caption"
                      target="_blank"
                      :href="
                        'http://www.google.com/maps/place/' +
                        deviceData.last_frames.frame_gps.latitude +
                        ',' +
                        deviceData.last_frames.frame_gps.longitude
                      ">
                      {{
                        deviceData.last_frames.frame_gps.geocode ? deviceData.last_frames.frame_gps.geocode : 'Ir a la posición'
                      }}
                    </a>
                  </span>
                </div>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.latitude') }}:</p>
                  <span class="expansion-panel-info-text"> {{ deviceData.last_frames.frame_gps.latitude }} </span>
                </div>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.longitude') }}:</p>
                  <span class="expansion-panel-info-text"> {{ deviceData.last_frames.frame_gps.longitude }} </span>
                </div>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.altitude') }}:</p>
                  <span class="expansion-panel-info-text">
                    {{
                      deviceData.last_frames.frame_gps.altitude ? deviceData.last_frames.frame_gps.altitude + ' m' : 'Sin datos'
                    }}
                  </span>
                </div>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.speed') }}:</p>
                  <span class="expansion-panel-info-text"> {{ deviceData.last_frames.frame_gps.speed + ' km/h' }} </span>
                </div>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.course') }}:</p>
                  <span class="expansion-panel-info-text"> {{ deviceData.last_frames.frame_gps.course + 'º' }} </span>
                </div>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.odometer') }}:</p>
                  <span class="expansion-panel-info-text">
                    {{ deviceData.last_frames.frame_gps.odometer.offset_applied + ' km' }}
                  </span>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="card-status expansion-panels mb-2" v-if="deviceData.last_frames.frame_status">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>
                    {{ $t('device.last_frames.frame_status.name') }}
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div class="expansion-panel-body">
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.battery') }}:</p>
                  <span class="expansion-panel-info-text">{{ deviceData.last_frames.frame_status.battery_voltage }}</span>
                </div>

                <v-divider class="my-2"></v-divider>

                <div class="expansion-panel-info text-center">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_status.alarms') }}</p>
                </div>

                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_status.ignition') }}:</p>
                  <span class="expansion-panel-info-text">{{
                    deviceData.last_frames.frame_status.contact ? 'Encendido' : 'Apagado'
                  }}</span>
                </div>
                <div v-if="deviceData.last_frames.frame_frigo" class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_status.engine_on') }}:</p>
                  <span class="expansion-panel-info-text">{{
                    deviceData.last_frames.frame_status.engine_on ? 'Encendido' : 'Apagado'
                  }}</span>
                </div>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_status.door') }}:</p>
                  <span class="expansion-panel-info-text">
                    {{ deviceData.last_frames.frame_status.door ? 'Abierta' : 'Cerrada' }}</span
                  >
                </div>
                <v-divider class="my-2"></v-divider>
                <div class="expansion-panel-info text-center">
                  <p class="expansion-panel-info-title">Warning</p>
                </div>
                <div v-for="(probe, index) in existingProbes" :key="index">
                  <div v-if="deviceData.last_frames.frame_frigo" class="expansion-panel-info">
                    <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_frigo.probe') }} {{ index + 1 }}:</p>
                    <span class="expansion-panel-info-text">{{ probe }}</span>
                  </div>
                </div>

                <v-divider class="my-2"></v-divider>

                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">Hours Limit</p>
                  ---
                </div>
                <div class="expansion-panel-info">
                  <p class="expansion-panel-info-title">Revision Needed:</p>
                  ---
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="card-enlace expansion-panels mb-2">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>
                    {{ $t('general.form.linked') }}
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div class="expansion-panel-body">
                <router-link :to="{ path: link }" class="expansion-panel-link">
                  <span class="expansion-panel-link-text"> {{ existingLinks }} </span>
                </router-link>
                <router-view />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="card-iccid expansion-panels mb-2" v-if="deviceData.device_data_from_frame.iccid">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col> ICCID </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div class="expansion-panel-body">
                <a
                  target="_blank"
                  :href="
                    'https://m2mmanager.matooma.com/es/home/parc-management/devices?simcardIccid=' +
                    deviceData.device_data_from_frame.iccid
                  "
                  class="expansion-panel-link"
                  style="cursor: pointer">
                  {{ deviceData.device_data_from_frame.iccid }}
                </a>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels class="card-tracker expansion-panels mb-2" v-if="deviceData.model == 'tracker'">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col> Detener Tracker </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content">
              <div id="expansion-panel-body-switch">
                <v-switch @change="turnOff" v-model="switch1" color="red" inset></v-switch>
                <p v-if="!switch1" color="primary" class="text-lg">Activado</p>
                <p v-else color="primary" class="text-lg">Detenido</p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
      </template>
    </v-snackbar>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </v-container>

  <!-- Mobile View -->

  <v-container v-else class="edit-device-container-mobile mt-5">
    <v-row class="header-title">
      <div class="breadcrumbs-container">
        <v-row>
          <h1>
            {{ $t('device.name') + ' ' + deviceData.device_sn }}
          </h1>
        </v-row>
        <v-row class="breadcrumbs breadcrumbs-edit-device-row">
          <v-breadcrumbs :items="items" class="pl-0">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-row>
      </div>
    </v-row>

    <v-row class="card-actions ma-3">
      <v-row class="card-actions-row">
        <v-btn
          @click="createDocument"
          class="card-actions-button ma-3 d-flex justify-center"
          color="primary"
          :disabled="!isApacheModel">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="mr-2" />
          <span>{{ $t('device.actions.download_cfg') }}</span>
        </v-btn>
        <v-btn @click="openApacheBase" class="card-actions-button ma-3 d-flex justify-center white--text" color="#20c997">
          <font-awesome-icon icon="fa-solid fa-up-right-from-square" class="mr-2" />
          <span>{{ $t('device.actions.open_in_apachebase') }}</span>
        </v-btn>
        <v-btn class="card-actions-button ma-3 d-flex justify-center" color="error" disabled>
          <font-awesome-icon icon="fa-solid fa-trash" class="mr-2" />
          <span>{{ $t('device.actions.delete_device') }}</span>
        </v-btn>
      </v-row>
    </v-row>

    <DeviceDataForm ref="deviceDataForm" :deviceData="deviceData" @setDevices="setDevices"></DeviceDataForm>

    <v-row class="device-actions mx-3 mt-2">
      <v-expansion-panels class="card-frame-frigo expansion-panels mb-2" v-if="deviceData.last_frames.frame_frigo">
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col>
                  {{ $t('device.last_frames.frame_frigo.name') }}
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="expansion-panel-content">
            <div class="expansion-panel-body">
              <div class="atribute-date expansion-panel-info">
                <span class="expansion-panel-info-text text-tooltip" style="color: #b41c5c">{{ getFrigoTimestampTooltip }}</span>
              </div>

              <v-divider class="my-2"></v-divider>

              <div
                v-if="deviceData.last_frames.frame_frigo.probes_temperature.p1"
                class="frigo-probe1 atribute-date expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_frigo.probe1') }}:</p>
                <span class="expansion-panel-info-text">
                  {{
                    !isNaN(deviceData.last_frames.frame_frigo.probes_temperature.p1)
                      ? deviceData.last_frames.frame_frigo.probes_temperature.p1.toFixed(1)
                      : '---'
                  }}{{ deviceData.device_data_from_frame.probes_type.p1 == 'hum' ? '% RH' : 'ºC' }}
                </span>
              </div>

              <div
                v-if="deviceData.last_frames.frame_frigo.probes_temperature.p2"
                class="frigo-probe2 atribute-date expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_frigo.probe2') }}:</p>
                <span class="expansion-panel-info-text">
                  {{
                    !isNaN(deviceData.last_frames.frame_frigo.probes_temperature.p2)
                      ? deviceData.last_frames.frame_frigo.probes_temperature.p2.toFixed(1)
                      : '---'
                  }}{{ deviceData.device_data_from_frame.probes_type.p2 == 'hum' ? '% RH' : 'ºC' }}
                </span>
              </div>
              <div
                v-if="deviceData.last_frames.frame_frigo.probes_temperature.p3"
                class="probe-sonda3 atribute-date expansion-panel-info">
                <p class="expansion-panel-info-title">Sonda 3:</p>
                <span class="expansion-panel-info-text">
                  {{
                    !isNaN(deviceData.last_frames.frame_frigo.probes_temperature.p3)
                      ? deviceData.last_frames.frame_frigo.probes_temperature.p4.toFixed(1)
                      : '---'
                  }}{{ deviceData.device_data_from_frame.probes_type.p3 == 'hum' ? '% RH' : 'ºC' }}
                </span>
              </div>
              <div
                v-if="deviceData.last_frames.frame_frigo.probes_temperature.p4"
                class="probe-sonda4 atribute-date expansion-panel-info">
                <p class="expansion-panel-info-title">Sonda 4:</p>
                <span class="expansion-panel-info-text">
                  {{
                    !isNaN(deviceData.last_frames.frame_frigo.probes_temperature.p4)
                      ? deviceData.last_frames.frame_frigo.probes_temperature.p4.toFixed(1)
                      : '---'
                  }}{{ deviceData.device_data_from_frame.probes_type.p4 == 'hum' ? '% RH' : 'ºC' }}
                </span>
              </div>
              <div class="atribute-carplate atribute-date expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.atribute.carplate') }}:</p>
                <span class="expansion-panel-info-text">
                  {{
                    deviceData.device_data_from_frame.carplate ? deviceData.device_data_from_frame.carplate : deviceData.device_sn
                  }}
                </span>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="card-frame-gps expansion-panels mb-2" v-if="deviceData.last_frames.frame_gps">
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col>
                  {{ $t('device.last_frames.frame_gps.name') }}
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="expansion-panel-content">
            <div class="expansion-panel-body">
              <div class="expansion-panel-info">
                <span class="expansion-panel-info-text text-tooltip" style="color: #b41c5c">{{ getGpsTimestampTooltip }}</span>
              </div>
              <v-divider class="my-2"></v-divider>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.position') }}:</p>
                <span class="expansion-panel-info-text">
                  <a
                    class="text-caption"
                    target="_blank"
                    :href="
                      'http://www.google.com/maps/place/' +
                      deviceData.last_frames.frame_gps.latitude +
                      ',' +
                      deviceData.last_frames.frame_gps.longitude
                    ">
                    {{ deviceData.last_frames.frame_gps.geocode ? deviceData.last_frames.frame_gps.geocode : 'Ir a la posición' }}
                  </a>
                </span>
              </div>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.latitude') }}:</p>
                <span class="expansion-panel-info-text"> {{ deviceData.last_frames.frame_gps.latitude }} </span>
              </div>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.longitude') }}:</p>
                <span class="expansion-panel-info-text"> {{ deviceData.last_frames.frame_gps.longitude }} </span>
              </div>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.altitude') }}:</p>
                <span class="expansion-panel-info-text">
                  {{ deviceData.last_frames.frame_gps.altitude ? deviceData.last_frames.frame_gps.altitude + ' m' : 'Sin datos' }}
                </span>
              </div>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.speed') }}:</p>
                <span class="expansion-panel-info-text"> {{ deviceData.last_frames.frame_gps.speed + ' km/h' }} </span>
              </div>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.course') }}:</p>
                <span class="expansion-panel-info-text"> {{ deviceData.last_frames.frame_gps.course + 'º' }} </span>
              </div>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.odometer') }}:</p>
                <span class="expansion-panel-info-text">
                  {{ deviceData.last_frames.frame_gps.odometer.offset_applied + ' km' }}
                </span>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="card-status expansion-panels mb-2" v-if="deviceData.last_frames.frame_status">
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col>
                  {{ $t('device.last_frames.frame_status.name') }}
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="expansion-panel-content">
            <div class="expansion-panel-body">
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_gps.battery') }}:</p>
                <span class="expansion-panel-info-text">{{ deviceData.last_frames.frame_status.battery_voltage }}</span>
              </div>

              <v-divider class="my-2"></v-divider>

              <div class="expansion-panel-info text-center">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_status.alarms') }}</p>
              </div>

              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_status.ignition') }}:</p>
                <span class="expansion-panel-info-text">{{
                  deviceData.last_frames.frame_status.contact ? 'Encendido' : 'Apagado'
                }}</span>
              </div>
              <div v-if="deviceData.last_frames.frame_frigo" class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_status.engine_on') }}:</p>
                <span class="expansion-panel-info-text">{{
                  deviceData.last_frames.frame_status.engine_on ? 'Encendido' : 'Apagado'
                }}</span>
              </div>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_status.door') }}:</p>
                <span class="expansion-panel-info-text">
                  {{ deviceData.last_frames.frame_status.door ? 'Abierta' : 'Cerrada' }}</span
                >
              </div>
              <v-divider class="my-2"></v-divider>
              <div class="expansion-panel-info text-center">
                <p class="expansion-panel-info-title">Warning</p>
              </div>
              <div v-for="(probe, index) in existingProbes" :key="index">
                <div v-if="deviceData.last_frames.frame_frigo" class="expansion-panel-info">
                  <p class="expansion-panel-info-title">{{ $t('device.last_frames.frame_frigo.probe') }} {{ index + 1 }}:</p>
                  <span class="expansion-panel-info-text">{{ probe }}</span>
                </div>
              </div>

              <v-divider class="my-2"></v-divider>

              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">Hours Limit</p>
                ---
              </div>
              <div class="expansion-panel-info">
                <p class="expansion-panel-info-title">Revision Needed:</p>
                ---
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="card-enlace expansion-panels mb-2">
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col>
                  {{ $t('general.form.linked') }}
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="expansion-panel-content">
            <div class="expansion-panel-body text-center">
              <router-link :to="{ path: link }" class="expansion-panel-link">
                <span class="expansion-panel-link-text"> {{ existingLinks }} </span>
              </router-link>
              <router-view />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="card-iccid expansion-panels mb-2" v-if="deviceData.device_data_from_frame.iccid">
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col> ICCID </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="expansion-panel-content">
            <div class="expansion-panel-body text-center">
              <a
                target="_blank"
                :href="
                  'https://m2mmanager.matooma.com/es/home/parc-management/devices?simcardIccid=' +
                  deviceData.device_data_from_frame.iccid
                "
                class="expansion-panel-link"
                style="cursor: pointer">
                {{ deviceData.device_data_from_frame.iccid }}
              </a>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="card-tracker expansion-panels mb-2" v-if="deviceData.model == 'tracker'" disabled>
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col> Detener Tracker </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="expansion-panel-content">
            <div id="expansion-panel-body-switch">
              <v-switch @change="turnOff" v-model="switch1" color="red" inset></v-switch>
              <p v-if="!switch1" color="primary" class="text-lg">Activado</p>
              <p v-else color="primary" class="text-lg">Detenido</p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row class="d-flex mt-10" style="justify-content: space-around">
      <v-btn color="primary" class="d-flex justify-space-between" @click="save">
        <v-icon>mdi-content-save</v-icon>{{ $t('general.form.save') }}
      </v-btn>
      <v-btn color="red" class="d-flex justify-space-between" @click="goBack">
        <v-icon class="white--text">mdi-exit-to-app</v-icon>
        <span class="white--text">{{ $t('general.form.exit') }}</span>
      </v-btn>
    </v-row>

    <ErrorDialog
      :isActive="errorDialog"
      v-bind:tittleText="$t('general.dialog.error.title')"
      :messageText="errorText"
      v-bind:acceptButtonText="$t('general.dialog.error.accept_button')"
      v-on:accept-dialog="errorDialog = false">
    </ErrorDialog>
  </v-container>
</template>

<script>
import router from '../../../../plugins/router/index';
import DeviceDataForm from '../../../../components/forms/deviceDataForm/deviceDataForm.vue';
import { modifyDeviceByAdmin } from '../../../../viewModels/adminViewModel';
import { ModifyDeviceByAdminException } from '../../../../server/petitions/devices/modifyDeviceByAdmin';
import ErrorDialog from '../../../../components/errorDialog.vue';
import { getConfig } from '../../../../viewModels/reportsViewModel';
import { turnOff } from '../../../../viewModels/devicesViewModel';

export default {
  components: {
    DeviceDataForm,
    ErrorDialog,
  },
  props: {
    deviceData: {
      type: Object,
    },
  },
  data: () => ({
    snackbarMessage: '',
    isUserNoDuplicate: false,
    isApacheModel: true,
    switch1: false,
    errorDialog: false,
    errorText: '',
    devicesList: [],
    link: '',
    user: null,
    txtLoading: false,
    snackbar: false,
    timeout: 4000,
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '../../../dashboard/temperatures',
      },
      {
        text: 'Dispositivos',
        disabled: false,
        href: '/admin/devices',
      },
      {
        text: 'Dispositivo',
        disabled: true,
        href: '',
      },
    ],
    apache: 'apache',
    apache4: 'apache4',
  }),

  methods: {
    async turnOff() {
      const res = await turnOff(this.deviceData.device_sn, this.switch1);
      if (res.result !== 'SUCCESS') {
      } else {
      }
    },
    deletedCorrectly() {
      this.snackbarMessage = this.$t('user.form.user_deleted');
      this.isDeletedUser = true;
    },
    userRepeat() {
      this.isUserNoDuplicate = true;
      this.snackbarMessage = this.$t('user.form.user_repeat');
      this.snackbar = true;
    },
    userNoRepeat() {
      this.snackbarMessage = this.$t('user.form.user_saved');
      this.isUserNoDuplicate = true;
    },
    save: async function () {
      try {
        let deviceDataForm = this.$refs.deviceDataForm;
        if (deviceDataForm.validate()) {
          let device_sn = Number(this.deviceData.device_sn);
          let carplate = deviceDataForm.carplate;
          let model = deviceDataForm.selectedModel;
          let submodel = deviceDataForm.selectedSubmodel;
          let distributor = deviceDataForm.selectedDistributor;
          let workshop = deviceDataForm.selectedWorkshop;
          let company = deviceDataForm.selectedCompany;
          let iccid = deviceDataForm.iccid;
          let password = deviceDataForm.password;
          let aes = {
            iv: deviceDataForm.aesIv,
            key: deviceDataForm.aesKey,
          };
          let certificate_date = deviceDataForm.certificateDate;
          let third_party_services = {
            webfleet: {
              type: 'webfleet',
              account: deviceDataForm.webfleetAccount ?? '',
              username: deviceDataForm.webfleetUsername ?? '',
              password: deviceDataForm.webfleetPassword ?? '',
              object_number: deviceDataForm.webfleetObjectNumber ?? '',
              object_uid: deviceDataForm.webfleetObjectUid ?? '',
            },
            carrier: {
              api_key: deviceDataForm.carrierApiKey ?? '',
              tru_serial_number: deviceDataForm.carrierTruSerialNumber ?? '',
              asset_id: deviceDataForm.carrierAssetId ?? '',
            },
          };
          let notes = deviceDataForm.notes;
          let gps_enabled = deviceDataForm.isGPSEnabled;
          let enabled = deviceDataForm.enabled;
          let users = deviceDataForm.userIds;
          let usersToRemoveDevice = deviceDataForm.usersToRemoveDevice;
          let linked_to = deviceDataForm.linkedTo;
          let tracker_disabled = deviceDataForm.stop_flag;
          let vehicleType = deviceDataForm.vehicleType;

          const payload = {
            serial_number: device_sn,
            model: model,
            submodel: submodel,
            distributor: distributor,
            workshop: workshop,
            company: company,
            iccid: iccid,
            password: password,
            aes: aes,
            certificate_date: certificate_date,
            third_party_services: third_party_services,
            notes: notes,
            gps_enabled: gps_enabled,
            enabled: enabled,
            usersToModify: users,
            usersToRemoveDevice: usersToRemoveDevice,
            linked_to: linked_to,
            tracker_disabled: tracker_disabled,
            carplate: carplate,
            vehicleType: vehicleType,
          };

          if (deviceDataForm.isCorrectCarrierData) {
            this.snackbar = true;
            this.snackbarMessage = 'Datos correctos';
            await modifyDeviceByAdmin(payload);
            if (this.$vuetify.breakpoint.mobile) this.goBack();
            if (this.isUserNoDuplicate) this.snackbar = true;
            if (this.isDeletedUser) this.snackbar = true;
          } else {
            this.snackbar = true;
            this.snackbarMessage = 'Datos incorrectos';
          }
        }
      } catch (e) {
        switch (e.code) {
          case ModifyDeviceByAdminException.unknownError:
          case ModifyDeviceByAdminException.incorrectParameters:
          case ModifyDeviceByAdminException.invalidToken:
          case ModifyDeviceByAdminException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
    goBack: function () {
      router.push({ name: 'devices' });
    },

    createLink() {
      let lastLinkPart;
      for (let device of this.devicesList) {
        if (device.device_sn === this.deviceData.linked_to) {
          lastLinkPart = device.device_sn;
          this.link = `/admin/devices/edit/${lastLinkPart}`;
        }
      }
    },

    setDevices(list) {
      this.devicesList = list;
      this.createLink();
    },

    getTimestampTooltip(timestampDate, actualDate) {
      const oneDay = 24 * 60 * 60 * 1000;
      const date = new Date(timestampDate);
      const formatedDate = this.formatDate2(timestampDate);
      const diffDays = Math.round(Math.abs((actualDate - date) / oneDay));

      if (diffDays < 1) {
        const diffHours = Math.round(Math.abs((actualDate - date) / 1000 / 3600));
        if (diffHours > 1) {
          return this.$t('dashboard.details.last_measure_hours', {
            hours: diffHours,
          });
        }
        const diffMinutes = Math.round(Math.abs((actualDate - date) / 1000 / 60));
        if (diffMinutes > 1) {
          return this.$t('dashboard.details.last_measure_minutes', {
            minutes: diffMinutes,
          });
        }
        return this.$t('dashboard.details.last_measure_recently');
      }

      if (diffDays < 7) {
        return this.$t('dashboard.details.last_measure_in_days', {
          days: diffDays,
        });
      }

      return this.$t('dashboard.details.last_measure_date', {
        date: formatedDate,
      });
    },

    formatDate(date) {
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return new Date(date).toLocaleString('default', options);
    },
    formatDate2: function (date) {
      let months = [
        this.$t('general.months.january'),
        this.$t('general.months.february'),
        this.$t('general.months.march'),
        this.$t('general.months.april'),
        this.$t('general.months.may'),
        this.$t('general.months.june'),
        this.$t('general.months.july'),
        this.$t('general.months.august'),
        this.$t('general.months.september'),
        this.$t('general.months.october'),
        this.$t('general.months.november'),
        this.$t('general.months.december'),
      ];
      let splitedDate = date.split('-');
      let formatedDate = `${new Date(date).getDate()} ${this.$t('general.form.of')} ${
        months[new Date(date).getMonth()]
      } ${this.$t('general.form.of')} ${splitedDate[0]}`;
      return formatedDate;
    },

    openApacheBase() {
      const url_base = process.env.VUE_APP_CHOFER;
      window.open(`${url_base}autologin?deviceId=${this.deviceData.device_sn}&secret=${this.deviceData.password}`);
    },

    createDocument: async function (device) {
      this.txtLoading = true;
      device = this.deviceData;

      let payload = {
        IdentDevice: device.device_sn,
        AesIv: device.aes.iv,
        AesKey: device.aes.key,
        StorePeriod: 1,
        IdentPlate: device.device_data_from_frame.carplate ? device.device_data_from_frame.carplate : '',
      };

      try {
        this.deviceData.model == this.apache || this.deviceData.model == this.apache4 ? await getConfig(payload) : '';
        this.txtLoading = false;
      } catch (error) {
        throw { error, date: new Date() };
      }
    },
    certificate: async function () {
      router.push({
        name: 'certificate',
        params: { device_sn: this.deviceData.device_sn, deviceData: this.deviceData },
      });
    },
  },

  mounted() {
    this.isApacheModel = this.deviceData.model == this.apache || this.deviceData.model == this.apache4 ? true : false;
    this.createLink();
    if (this.deviceData.stop_flag) {
      this.switch1 = this.deviceData.stop_flag;
    }
  },

  computed: {
    snackbarColor() {
      return this.snackbarMessage === this.$t('user.form.user_repeat') || this.snackbarMessage === 'Datos incorrectos'
        ? 'red'
        : 'success';
    },
    getFrigoTimestampTooltip() {
      return this.getTimestampTooltip(this.deviceData.last_frames.frame_frigo.device_timestamp, new Date());
    },

    getGpsTimestampTooltip() {
      return this.getTimestampTooltip(this.deviceData.last_frames.frame_gps.gps_timestamp, new Date());
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    existingProbes() {
      let existing = [];
      let probes = Object.values(this.deviceData.device_data_from_frame.probes_type);

      for (let sonda of probes) {
        if (sonda != null) {
          if (sonda === 'temp') {
            sonda = 'Temperatura';
          }
          existing.push(sonda);
        }
      }
      return existing;
    },

    existingLinks() {
      if (this.deviceData.linked_to) {
        return this.deviceData.linked_to;
      }
      return 'Ningún dispositivo enlazado';
    },
  },
};
</script>
