<template>
  <div>
    <div v-show="!isMobile" style="width: 45rem; text-align: center">
      <v-carousel hide-delimiters style="width: 100%; height: 27rem">
        <div v-if="isLoading" style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
          <v-progress-circular color="primary" size="70" indeterminate></v-progress-circular>
        </div>
        <v-carousel-item
          v-else
          v-for="(item, i) in chartDataFilter"
          reverse-transition="fade-transition"
          transition="fade-transition">
          <LineChartGenerator
            :chart-data="item"
            :chart-options="
              i === 0
                ? chartOptionsAll
                : item.datasets[0]['yAxesID'] === 'temp'
                ? chartOptionsTemp
                : item.datasets[0]['yAxesID'] === 'hum'
                ? chartOptionsHum
                : item.datasets[0]['yAxesID'] === 'airq'
                ? chartOptionsAirq
                : item.datasets[0]['yAxesID'] === 'frigoMachine'
                ? chartOptionsFrigoMachine
                : chartOptionsCarrierMachine
            "
            :chart-id="item.datasets[0]['yAxesID']"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        /></v-carousel-item>
      </v-carousel>
    </div>
    <div v-show="isMobile" id="container-carousel">
      <v-carousel id="carouselId" show-arrows-on-hover hide-delimiters style="width: 400px">
        <div v-if="isLoading" style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%">
          <v-progress-circular color="primary" size="70" indeterminate></v-progress-circular>
        </div>
        <v-carousel-item
          v-for="(item, i) in chartDataFilter"
          id="carousel-itemId"
          reverse-transition="fade-transition"
          transition="fade-transition">
          <LineChartGenerator
            style="height: 380px"
            id="linechart-mobile"
            :chart-data="item"
            :chart-options="
              i === 0
                ? chartOptionsAll
                : item.datasets[0]['yAxesID'] === 'temp'
                ? chartOptionsTemp
                : item.datasets[0]['yAxesID'] === 'hum'
                ? chartOptionsHum
                : item.datasets[0]['yAxesID'] === 'airq'
                ? chartOptionsAirq
                : item.datasets[0]['yAxesID'] === 'frigoMachine'
                ? chartOptionsFrigoMachine
                : chartOptionsCarrierMachine
            "
            :chart-id="item.datasets[0]['yAxesID']"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height" />
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';

import {
  Chart as ChartJS,
  Filler,
  Title,
  Tooltip,
  Legend,
  LineElement,
  TimeScale,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, Filler, LineElement, LinearScale, TimeScale, CategoryScale, PointElement);

import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

import { getFramesForLiveTemp } from '../../server/petitions/devices/getFramesForLiveTemp';
import { getFramesForLiveFrigoMachine } from '../../server/petitions/devices/getFramesForLiveFrigoMachine';
import { getToken } from '../../viewModels/userViewModel';
import { getAlarms } from '../../viewModels/alarmsViewModel';
import { getFrameMachineFromCarrier } from '../../utils/apiCarrier';

export default {
  props: {
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  name: 'LineChart',
  components: {
    LineChartGenerator,
  },
  data() {
    return {
      isLoading: true,
      chartBackgroundColors: {
        color1: '#47c48d',
        color2: '#385675',
        color3: '#F3A433',
        color4: '#f301f6',
      },
      alarmBackgroundColors: {
        p1: 'rgba(255, 54, 54,.3)',
        p2: 'rgba(97, 160, 255,.3)',
        p3: 'rgba(117, 255, 148,.3)',
        p4: 'rgba(255, 199, 212,.3)',
        p5: 'rgba(193, 193, 193,.3)',
        p6: 'rgba(255, 218, 174,.3)',
        p7: 'rgba(242, 255, 174,.3)',
        p8: 'rgba(255, 226, 250,.3)',
      },
      alarms: [],
      liveTemperatureFrames: [],
      liveFrigoMachineFrames: [],
      chartsDatas: [],
      chartDataTemp: {
        datasets: [],
      },
      chartDataHum: {
        datasets: [],
      },
      chartDataAirq: {
        datasets: [],
      },
      chartDataFrigoMachine: {
        datasets: [],
      },
      chartDataCarrierMachine: {
        datasets: [],
      },
      chartDataAll: {
        datasets: [],
      },
      dataset1: null,
      dataset2: null,
      dataset3: null,
      dataset4: null,
      datasets1: null,
      datasets2: null,
      datasets3: null,
      datasets4: null,
      datasets5: null,
      temp_setpoint: null,
      temp_ambient: null,
      temp_supply: null,
      temp_return: null,
      chartDataFilter: null,
      setpointTemp1: null,
      returnAirTemp1: null,
      supplyAirTemp1: null,
      isTheFirstFrame: true,
      chartOptionsTemp: {
        animation: false,
        responsive: true,
        lineTension: 1,
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
            filter: function (label) {
              if (label.text !== 'No data') return true;
            },
          },
        },
        plugins: {
          annotation: {
            annotations: [],
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'nearest',
            callbacks: {
              label: function (context) {
                let label = context.dataset.label;

                if (label === context.dataset.toolpitLabel) {
                  return context.dataset.toolpitLabel;
                }
                if (context.dataset.yAxesID == 'setpoint') {
                  return (label += ': ' + context.formattedValue + ' ºC');
                }

                if (context.formattedValue) {
                  return (label += ': ' + context.formattedValue + ' ºC');
                }
                return label;
              },
            },
          },
        },
        scales: {},
      },
      chartOptionsHum: {
        animation: false,
        responsive: true,
        lineTension: 1,
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
            filter: function (label) {
              if (label.text !== 'No data') return true;
            },
          },
        },
        plugins: {
          annotation: {
            annotations: [],
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'nearest',
            callbacks: {
              label: function (context) {
                let label = context.dataset.label;

                if (label === context.dataset.toolpitLabel) {
                  return context.dataset.toolpitLabel;
                }

                if (context.dataset.yAxesID == 'hum') {
                  return (label += ': ' + context.formattedValue + ' %RH');
                }

                return label;
              },
            },
          },
        },
        scales: {},
      },
      chartOptionsAirq: {
        animation: false,
        responsive: true,
        lineTension: 1,
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
            filter: function (label) {
              if (label.text !== 'No data') return true;
            },
          },
        },
        plugins: {
          annotation: {
            annotations: [],
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'nearest',
            callbacks: {
              label: function (context) {
                let label = context.dataset.label;

                if (label === context.dataset.toolpitLabel) {
                  return context.dataset.toolpitLabel;
                }

                if (context.dataset.yAxesID == 'airq') {
                  return (label += ': ' + context.formattedValue + ' AQ');
                }

                return label;
              },
            },
          },
        },
        scales: {},
      },
      chartOptionsFrigoMachine: {
        animation: false,
        responsive: true,
        lineTension: 1,
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
            filter: function (label) {
              if (label.text !== 'No data') return true;
            },
          },
        },
        plugins: {
          annotation: {
            annotations: [],
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'nearest',
            callbacks: {
              label: function (context) {
                let label = context.dataset.label;

                if (label === context.dataset.toolpitLabel) {
                  return context.dataset.toolpitLabel;
                }
                if (context.dataset.yAxesID == 'frigoMachine') {
                  return (label += ': ' + context.formattedValue + ' ºC');
                }
                if (context.formattedValue) {
                  return (label += ': ' + context.formattedValue + ' ºC');
                }

                return label;
              },
            },
          },
        },
        scales: {},
      },
      chartOptionsCarrierMachine: {
        animation: false,
        responsive: true,
        lineTension: 1,
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
            filter: function (label) {
              if (label.text !== 'No data') return true;
            },
          },
        },
        plugins: {
          annotation: {
            annotations: [],
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'nearest',
            callbacks: {
              label: function (context) {
                let label = context.dataset.label;

                if (label === context.dataset.toolpitLabel) {
                  return context.dataset.toolpitLabel;
                }
                if (context.dataset.yAxesID == 'carrier') {
                  return (label += ': ' + context.formattedValue + ' ºC');
                }
                if (context.formattedValue) {
                  return (label += ': ' + context.formattedValue + ' ºC');
                }

                return label;
              },
            },
          },
        },
        scales: {},
      },
      chartOptionsAll: {
        animation: false,
        responsive: true,
        lineTension: 1,
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
            filter: function (label) {
              if (label.text !== 'No data') return true;
            },
          },
        },
        plugins: {
          annotation: {
            annotations: [],
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            position: 'nearest',
            callbacks: {
              label: function (context) {
                let label = context.dataset.label;

                if (label === context.dataset.toolpitLabel) {
                  return context.dataset.toolpitLabel;
                }

                if (context.dataset.yAxesID == 'hum') {
                  return (label += ': ' + context.formattedValue + ' %RH');
                }

                if (context.dataset.yAxesID == 'airq') {
                  return (label += ': ' + context.formattedValue + ' AQ');
                }
                if (context.dataset.yAxesID == 'setpoint') {
                  return (label += ': ' + context.formattedValue + ' ºC');
                }

                if (context.formattedValue) {
                  return (label += ': ' + context.formattedValue + ' ºC');
                }

                return label;
              },
            },
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH:mm',
              },
              tooltipFormat: 'dd MMM yyyy HH:mm (XXX)',
            },
            distribution: 'linear',
          },
          y: {
            position: 'left',
            ticks: {
              callback: function (value, index, values) {
                return Math.floor(value * 10) / 10 + ' ºC';
              },
            },
            suggestedMax: 40,
            suggestedMin: -10,
            id: 'all',
          },
        },
      },
    };
  },
  methods: {
    async getDeviceFrames(device) {
      if (device?.device_sn) {
        this.isLoading = true;
        let token = getToken();
        this.liveTemperatureFrames = await getFramesForLiveTemp(device.device_sn, token);
        this.liveFrigoMachineFrames = await getFramesForLiveFrigoMachine(device.device_sn, token);
        this.alarms = await getAlarms();

        let date = new Date(new Date().setHours(new Date().getHours() - 12));
        let frameDate;
        let frameDateInFormat;
        if (this.liveTemperatureFrames?.length > 0) {
          frameDate = this.liveTemperatureFrames[this.liveTemperatureFrames.length - 1].device_timestamp;
        }

        if (frameDate) {
          frameDateInFormat = new Date(frameDate);
        }

        //MÁQUINA DE FRÍO
        //CARRIER//

        let apiKey = device.third_party_services.carrier?.api_key;
        let assetId = device.third_party_services.carrier?.asset_id;
        let carrierData;

        let startDate = date.toISOString();
        let endDate = new Date().toISOString();

        if (apiKey) carrierData = await getFrameMachineFromCarrier(apiKey, assetId, startDate, endDate);

        let frameFrigoMachineDate;
        let frameFrigoMachineDateInFormat;

        if (this.liveFrigoMachineFrames?.length > 0) {
          frameFrigoMachineDate = this.liveFrigoMachineFrames[this.liveFrigoMachineFrames.length - 1].device_timestamp;
        }
        if (frameFrigoMachineDate) {
          frameFrigoMachineDateInFormat = new Date(frameFrigoMachineDate);
        }

        if (frameDateInFormat && date <= frameDateInFormat) {
          this.fillData(this.liveTemperatureFrames ?? [], device, this.liveFrigoMachineFrames ?? [], carrierData?.data ?? []);
          this.isLoading = false;
        } else {
          this.fillData([], device, this.liveFrigoMachineFrames ?? [], carrierData?.data ?? []);
          this.isLoading = false;
        }
      }
    },

    pushData(data, i, probe) {
      data.push({
        x: i.device_timestamp,
        y: probe,
      });
    },
    pushDataCarrier(data, i, probe) {
      if (this.isTheFirstFrame) {
        i.eventDateTimeUTC = new Date(new Date(i.eventDateTimeUTC).setMinutes(0, 0, 0)).toISOString();
        this.isTheFirstFrame = false;
      }
      data.push({
        x: i.eventDateTimeUTC,
        y: probe,
      });
    },
    generalDataset(dataset, label, probe, color) {
      dataset = {
        label: label,
        yAxesID: probe,
        borderColor: color,
        borderWidth: 2,
        pointRadius: 0,
        data: [],
      };
      return dataset;
    },

    generalDatasetFrigoMachine(dataset, label, color) {
      label = this.$t(`dashboard.configuration.${label}`);

      dataset = {
        label: label,
        yAxesID: 'frigoMachine',
        borderColor: color,
        borderWidth: 2,
        pointRadius: 0,
        data: [],
      };
      return dataset;
    },
    generalDatasetCarrierMachine(dataset, label, color) {
      label = this.$t(`dashboard.configuration.${label}`);

      dataset = {
        label: label,
        yAxesID: 'carrier',
        borderColor: color,
        borderWidth: 2,
        pointRadius: 0,
        data: [],
      };
      return dataset;
    },

    iteratorMethod(
      probeTypeLength,
      typeProbeArray,
      probesLength,
      probeLabels,
      chartBackgroundColors,
      chartData,
      datasetFunction,
      dataset,
    ) {
      for (let i = 0; i < probeTypeLength; i++) {
        const valuesOfArrayType = Object.entries(typeProbeArray[i][1]);
        const lengthToEachType = Object.entries(typeProbeArray[i][1]).length;
        if (lengthToEachType > 0) {
          for (let x = 0; x < lengthToEachType; x++) {
            for (let y = 0; y < probesLength; y++) {
              if (valuesOfArrayType[x][0] === `p${y + 1}`) {
                this[`${dataset}${y + 1}`] = datasetFunction(
                  this[`${dataset}${y + 1}`],
                  probeLabels[y],
                  typeProbeArray[i][0],
                  chartBackgroundColors[`color${y + 1}`],
                );

                if (this[`${dataset}${y + 1}`]) {
                  chartData.datasets.push(this[`${dataset}${y + 1}`]);
                }
              }
            }
          }
        }
      }
    },

    iteratorMethodFrigoMachine(dataTypesLength, data, chartData, chartBackgroundColors, datasetFunction) {
      let x = 0;
      for (let i = 0; i < dataTypesLength; i++) {
        if (data[i][0].includes('temp')) {
          this[`${data[i][0]}`] = datasetFunction(this[`${data[i][0]}`], data[i][0], chartBackgroundColors[`color${x + 1}`]);
          if (this[`${data[i][0]}`]) {
            chartData.datasets.push(this[`${data[i][0]}`]);
          }
          x++;
        }
      }
    },
    iteratorMethodCarrier(data, chartData, chartBackgroundColors, datasetFunction) {
      let indexP = 0;
      data.some((d, index) => {
        if (d.temperatureInfo.setpointTemp1) {
          indexP = index;
          return true;
        }
        return false;
      });

      let x = 0;
      for (let i = 0; i < 1; i++) {
        Object.entries(data[indexP]?.temperatureInfo).forEach((element, index) => {
          if (element[0].includes('Temp1') && element[0] != 'datacoldTemp1' && element[0] != 'oneWireTemp1') {
            let temperatureInfo = Object.entries(data[indexP].temperatureInfo)[index][0];
            this[`${element[0]}`] = datasetFunction(
              this[`${temperatureInfo}`],
              temperatureInfo,
              chartBackgroundColors[`color${x + 1}`],
            );

            if (this[`${element[0]}`]) {
              chartData.datasets.push(this[`${temperatureInfo}`]);
            }
            x++;
          }
        });
      }
    },
    fillData(dataFrames, device, frigoMachineFrames, carrierData) {
      this.chartDataTemp.datasets = [];
      this.chartDataHum.datasets = [];
      this.chartDataAirq.datasets = [];
      this.chartDataFrigoMachine.datasets = [];
      this.chartDataCarrierMachine.datasets = [];
      this.chartDataAll.datasets = [];
      this.dataset1 = null;
      this.dataset2 = null;
      this.dataset3 = null;
      this.dataset4 = null;
      this.datasets1 = null;
      this.datasets2 = null;
      this.datasets3 = null;
      this.datasets4 = null;
      this.datasets5 = null;
      this.temp_setpoint = null;
      this.temp_ambient = null;
      this.temp_supply = null;
      this.temp_return = null;
      this.setpointTemp1 = null;
      this.returnAirTemp1 = null;
      this.supplyAirTemp1 = null;
      let deviceAlarms = this.alarmsConfig[device.device_sn] ?? {};

      this.chartOptionsAll.plugins.annotation.annotations = [];
      this.chartOptionsTemp.plugins.annotation.annotations = [];

      for (const probe in deviceAlarms) {
        this.chartOptionsAll.plugins.annotation.annotations.push({
          type: 'box',
          drawTime: 'beforeDraw',
          yMin: deviceAlarms[probe].min,
          yMax: deviceAlarms[probe].max,
          borderColor: '#A4A4A4',
          borderWidth: 1,
          backgroundColor: this.alarmBackgroundColors[probe],
          borderDash: [4, 4],
          borderDashOffset: 1,
        });
        this.chartOptionsTemp.plugins.annotation.annotations.push({
          type: 'box',
          drawTime: 'beforeDraw',
          yMin: deviceAlarms[probe].min,
          yMax: deviceAlarms[probe].max,
          borderColor: '#A4A4A4',
          borderWidth: 1,
          backgroundColor: this.alarmBackgroundColors[probe],
          borderDash: [4, 4],
          borderDashOffset: 1,
        });
      }
      this.chartDataTemp = {
        datasets: [],
      };

      this.chartDataHum = {
        datasets: [],
      };
      this.chartDataAirq = {
        datasets: [],
      };
      this.chartDataFrigoMachine = {
        datasets: [],
      };
      this.chartDataCarrierMachine = {
        datasets: [],
      };

      this.chartDataAll = {
        datasets: [],
      };

      let doorDataset = {
        label: this.$t('dashboard.temp.alarms.door'),
        yAxesID: 'puerta-abierta',
        backgroundColor: 'rgba(255, 168, 80, 0.3)',
        borderWidth: 2,
        pointRadius: 0,
        data: [],
        fill: 'start',
        toolpitLabel: this.$t('dashboard.temp.alarms.door'),
      };
      let frigoDataset = {
        data: [],
        label: this.$t('dashboard.temp.alarms.engine'),
        yAxesID: 'frigo',
        backgroundColor: 'rgba(0, 137, 255, 0.3)',
        pointRadius: 0,
        fill: 'end',
        toolpitLabel: this.$t('dashboard.temp.alarms.engine'),
      };
      let setpointDatasetCollection = {
        data: [],
        label: this.$t('dashboard.configuration.modify_setpoint'),
        yAxesID: 'setpoint',
        backgroundColor: 'rgba(255, 165, 0, 0.3)',
        pointRadius: 2,
        fill: false,
      };
      //COGEMOS TRAMA QUE CONTENGA LA MAYOR CANTIDAD DE SONDAS DE ENTRE LAS TRAMAS QUE RECIBIMOS DEL BACKEND
      // Si desconectamos una sonda posteriormente, de esta manera seguirá apareciendo en la gráfica.
      let greaterProbesNumber = 0;
      let frameWithGreaterProbesNumber = null;

      dataFrames.forEach((dataframe) => {
        const probesType2 = dataframe.probes_type;
        const propsCount2 = Object.keys(probesType2).reduce((count, key) => {
          return count + Object.keys(probesType2[key]).length;
        }, 0);

        if (propsCount2 > greaterProbesNumber) {
          greaterProbesNumber = propsCount2;
          frameWithGreaterProbesNumber = dataframe;
        }
      });

      //-------------------------PARA GRÁFICA CON TODAS LAS SONDAS-------------------------

      //DATASET EN ITERATORMETHOD FUNCTION
      const datasets = 'datasets';

      //OBJETO CON TODA INFO DE CADA TIPO DE SONDA -------> hum{p4: 50.3}, temp{p1:45, p2:30, p3:40}, airq:{}
      const typeProbeAll = frameWithGreaterProbesNumber?.probes_type;

      //ARRAY SACADO DEL OBJETO ANTERIOR
      const typeProbeArrayAll = Object.entries(typeProbeAll);

      //LENGTH DEL ARRAY ANTERIOR-----------> 3 (temp, hum, airq)
      const probeTypeLengthAll = Object.entries(typeProbeArrayAll).length;

      //-------------------------PARA GRÁFICA CON SONDAS INDIVIDUALES-------------------------

      //DATASET EN ITERATORMETHOD FUNCTION
      const dataset = 'dataset';

      //LONGITUD DEL ARRAY (TOTAL DE SONDAS)
      const probesLength = 8;

      //OBJETO CON TODA INFO DE CADA TIPO DE SONDA
      const typeProbe = frameWithGreaterProbesNumber.probes_type;

      //ARRAY SACADO DEL OBJETO ANTERIOR
      const typeProbeArrayTemp = Object.entries(typeProbe).filter((event) => event.includes('temp'));
      const typeProbeArrayAirq = Object.entries(typeProbe).filter((event) => event.includes('airq'));
      const typeProbeArrayHum = Object.entries(typeProbe).filter((event) => event.includes('hum'));

      //LENGTH DEL ARRAY ANTERIOR
      const probeTypeLengthTemp = Object.entries(typeProbeArrayTemp).length;
      const probeTypeLengthHum = Object.entries(typeProbeArrayHum).length;
      const probeTypeLengthAirq = Object.entries(typeProbeArrayAirq).length;

      //SETPOINT
      let setpointValue = device.last_frames.frame_frigo_machine?.frigo_machine?.temp_setpoint;

      //SONDAS
      const probeLabels = [
        this.$t('dashboard.probes.probe1') +
          (deviceAlarms.p1 ? ` (Min: ${deviceAlarms.p1.min} , Max: ${deviceAlarms.p1.max} )` : ''),
        this.$t('dashboard.probes.probe2') +
          (deviceAlarms.p2 ? ` (Min: ${deviceAlarms.p2.min} , Max: ${deviceAlarms.p2.max} )` : ''),
        this.$t('dashboard.probes.probe3') +
          (deviceAlarms.p3 ? ` (Min: ${deviceAlarms.p3.min} , Max: ${deviceAlarms.p3.max} )` : ''),
        this.$t('dashboard.probes.probe4') +
          (deviceAlarms.p4 ? ` (Min: ${deviceAlarms.p4.min} , Max: ${deviceAlarms.p4.max} )` : ''),
        this.$t('dashboard.configuration.modify_setpoint') + setpointValue,
      ];

      //COLOR SONDA
      const chartBackgroundColors = this.chartBackgroundColors;

      //GRAFICA TODAS SONDAS
      this.iteratorMethod(
        probeTypeLengthAll,
        typeProbeArrayAll,
        probesLength,
        probeLabels,
        chartBackgroundColors,
        this.chartDataAll,
        this.generalDataset,
        datasets,
      );

      //PARA GRÁFICA CON SONDAS TEMPERATURA
      this.iteratorMethod(
        probeTypeLengthTemp,
        typeProbeArrayTemp,
        probesLength,
        probeLabels,
        chartBackgroundColors,
        this.chartDataTemp,
        this.generalDataset,
        dataset,
      );
      //MÁQUINA DE FRÍO
      if (carrierData.length > 0) {
        this.iteratorMethodCarrier(
          carrierData,
          this.chartDataCarrierMachine,
          chartBackgroundColors,
          this.generalDatasetCarrierMachine,
        );
      } else if (frigoMachineFrames.length > 0) {
        this.iteratorMethodFrigoMachine(
          Object.keys(frigoMachineFrames[0]).length,
          Object.entries(frigoMachineFrames[0]),
          this.chartDataFrigoMachine,
          chartBackgroundColors,
          this.generalDatasetFrigoMachine,
        );
      }

      //PARA GRÁFICA CON SONDAS HUMEDAD
      this.iteratorMethod(
        probeTypeLengthHum,
        typeProbeArrayHum,
        probesLength,
        probeLabels,
        chartBackgroundColors,
        this.chartDataHum,
        this.generalDataset,
        dataset,
      );

      //PARA GRÁFICA CON SONDAS AIRE
      this.iteratorMethod(
        probeTypeLengthAirq,
        typeProbeArrayAirq,
        probesLength,
        probeLabels,
        chartBackgroundColors,
        this.chartDataAirq,
        this.generalDataset,
        dataset,
      );

      let drawDoor = false;
      let drawFrigo = false;
      let drawSetpoint = device.last_frames.frame_frigo_machine?.frigo_machine?.temp_setpoint;

      dataFrames.map((i) => {
        drawDoor |= i.door.isDoor;
        drawFrigo |= i.engine_time.isEngine;
        function flattenObject(obj, result = {}) {
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                flattenObject(obj[key], result);
              } else {
                result[key] = obj[key];
              }
            }
          }

          return result;
        }

        const unifiedObject = flattenObject(i.probes_type);

        if (this.dataset1) this.pushData(this.dataset1?.data, i, unifiedObject.p1);
        if (this.dataset2) this.pushData(this.dataset2?.data, i, unifiedObject.p2);
        if (this.dataset3) this.pushData(this.dataset3?.data, i, unifiedObject.p3);
        if (this.dataset4) this.pushData(this.dataset4?.data, i, unifiedObject.p4);
        if (this.datasets1) this.pushData(this.datasets1?.data, i, unifiedObject.p1);
        if (this.datasets2) this.pushData(this.datasets2?.data, i, unifiedObject.p2);
        if (this.datasets3) this.pushData(this.datasets3?.data, i, unifiedObject.p3);
        if (this.datasets4) this.pushData(this.datasets4?.data, i, unifiedObject.p4);
        if (this.datasets5)
          this.pushData(this.datasets5?.data, i, device.last_frames.frame_frigo_machine?.frigo_machine?.temp_setpoint);

        doorDataset?.data.push({
          x: i.door.timestamp,
          y: i.door.isDoor ? 0 : null,
        });
        frigoDataset?.data.push({
          x: i.engine_time.timestamp,
          y: i.engine_time.isEngine ? 0 : null,
        });
        setpointDatasetCollection?.data.push({
          x: i.device_timestamp,
          y: device.last_frames.frame_frigo_machine?.frigo_machine?.temp_setpoint,
        });
      });

      if (carrierData.length > 0) {
        carrierData.map((i) => {
          if (this.setpointTemp1) this.pushDataCarrier(this.setpointTemp1?.data, i, i.temperatureInfo.setpointTemp1);
          if (this.supplyAirTemp1) this.pushDataCarrier(this.supplyAirTemp1?.data, i, i.temperatureInfo.supplyAirTemp1);
          if (this.returnAirTemp1) this.pushDataCarrier(this.returnAirTemp1?.data, i, i.temperatureInfo.returnAirTemp1);
        });
      } else if (frigoMachineFrames.length > 0) {
        frigoMachineFrames.map((i) => {
          if (this.temp_setpoint) this.pushData(this.temp_setpoint?.data, i, i.temp_setpoint);
          if (this.temp_ambient) this.pushData(this.temp_ambient?.data, i, i.temp_ambient);
          if (this.temp_supply) this.pushData(this.temp_supply?.data, i, i.temp_supply);
          if (this.temp_return) this.pushData(this.temp_return?.data, i, i.temp_return);
        });
      }

      if (drawDoor) this.chartDataAll.datasets.push(doorDataset);
      if (drawFrigo) this.chartDataAll.datasets.push(frigoDataset);
      if (drawSetpoint) {
        this.chartDataAll.datasets.push(setpointDatasetCollection);
        this.chartDataTemp.datasets.push(setpointDatasetCollection);
      }

      //CONTIENE TODAS LAS GRÁFICAS
      this.chartsDatas.push(this.chartDataAll);
      this.chartsDatas.push(this.chartDataTemp);
      this.chartsDatas.push(this.chartDataHum);
      this.chartsDatas.push(this.chartDataAirq);
      this.chartsDatas.push(this.chartDataFrigoMachine);
      this.chartsDatas.push(this.chartDataCarrierMachine);

      this.chartDataFilter = this.chartsDatas.filter((item) => item.datasets.length > 0);
      for (let i of this.chartDataFilter) {
        if (i.datasets[0]['yAxesID'] === 'temp') {
          this.chartOptionsTemp.scales = {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:mm',
                },
                tooltipFormat: 'dd MMM yyyy HH:mm (XXX)',
              },
              distribution: 'linear',
            },
            y: {
              position: 'left',
              ticks: {
                callback: function (value, index, values) {
                  return Math.floor(value * 10) / 10 + ' ºC';
                },
              },
              suggestedMax: 50,
              suggestedMin: -10,
              id: 'temp',
            },
          };
        } else if (i.datasets[0]['yAxesID'] === 'hum') {
          this.chartOptionsHum.scales = {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:mm',
                },
                tooltipFormat: 'dd MMM yyyy HH:mm (XXX)',
              },
              distribution: 'linear',
            },
            y: {
              position: 'left',
              ticks: {
                callback: function (value, index, values) {
                  return Math.floor(value * 10) / 10 + ' %RH';
                },
              },
              suggestedMax: 100,
              suggestedMin: 0,
              id: 'hum',
            },
          };
        } else if (i.datasets[0]['yAxesID'] === 'airq') {
          this.chartOptionsAirq.scales = {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:mm',
                },
                tooltipFormat: 'dd MMM yyyy HH:mm (XXX)',
              },
              distribution: 'linear',
            },
            y: {
              position: 'left',
              ticks: {
                callback: function (value, index, values) {
                  return Math.floor(value * 10) / 10 + ' AQ';
                },
              },
              suggestedMax: 500,
              suggestedMin: 0,
              id: 'airq',
            },
          };
        } else if (i.datasets[0]['yAxesID'] === 'frigoMachine') {
          this.chartOptionsFrigoMachine.scales = {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:mm',
                },
                tooltipFormat: 'dd MMM yyyy HH:mm (XXX)',
              },
              distribution: 'linear',
            },
            y: {
              position: 'left',
              ticks: {
                callback: function (value, index, values) {
                  return Math.floor(value * 10) / 10 + ' ºC';
                },
              },
              suggestedMax: 50,
              suggestedMin: -10,
              id: 'frigoMachine',
            },
          };
        } else if (i.datasets[0]['yAxesID'] === 'carrier') {
          this.chartOptionsCarrierMachine.scales = {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                displayFormats: {
                  hour: 'HH:mm',
                },
                tooltipFormat: 'dd MMM yyyy HH:mm (XXX)',
              },
              ticks: {
                source: 'data',
                stepSize: 1,
                autoSkip: true,
                maxTicksLimit: 12,
                callback: (value) => `${value.split(':')[0]}:00`,
              },

              distribution: 'linear',
            },
            y: {
              position: 'left',
              ticks: {
                callback: function (value, index, values) {
                  return Math.floor(value * 10) / 10 + ' ºC';
                },
              },
              suggestedMax: 50,
              suggestedMin: -10,
              id: 'carrier',
            },
          };
        }
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    alarmsConfig() {
      let alarmsConfig = [];
      this.alarms.forEach((alarm) => {
        if (!alarmsConfig[alarm.device_sn]) {
          alarmsConfig[alarm.device_sn] = { ...alarm.probes_config };
        } else alarmsConfig[alarm.device_sn] = { ...alarmsConfig[alarm.device_sn], ...alarm.probes_config };
      });
      return alarmsConfig;
    },
  },
};
</script>

<style scoped></style>
