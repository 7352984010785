var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-2 ma-2"},[_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"text-overline font-weight-bold"},[_vm._v(_vm._s(_vm.$t('dashboard.details.cold_machine')))]),_c('v-divider',{staticClass:"py-1 mx-2",staticStyle:{"border-color":"#607d8b"}}),_c('div',{staticClass:"ml-1 mb-2 d-flex flex-column"},[_c('span',{staticClass:"mb-1"},[_c('v-icon',{class:_vm.checkStatus,attrs:{"x-small":""}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(_vm.coldMachineTimestamp)+" ")],1)]),(_vm.getTemps)?_c('v-row',{staticClass:"pt-5"},[_c('v-col',[(_vm.canChangeSetpoint)?_c('v-row',{staticClass:"justify-space-around"},[_c('v-chip',{staticClass:"justify-center ma-1 white--text blue-grey lighten-1",style:({
              backgroundColor: _vm.colorSetpoint,
              color: _vm.pretrip_received ? 'white !important' : '',
              width: '50%',
            }),attrs:{"label":"","small":""}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.configuration.modify_setpoint'))+_vm._s(_vm.tempSetpoint)+" ")]),_c('span',{on:{"click":function($event){return _vm.openModalSetPoint('setpoint')}}},[_c('div',[_c('v-icon',{style:({
                    color: _vm.theme === 'dark' ? 'white !important' : '',
                    margin: '0.2em',
                  })},[_vm._v("mdi-pencil-outline")])],1)])]),_c('v-chip',{staticClass:"justify-center ma-1 blue-grey lighten-4",style:({
              backgroundColor: _vm.colorPretrip,
              color: _vm.pretrip_received && _vm.pretrip ? 'white !important' : '',
              margin: '0.2em',
              width: '35%',
            }),attrs:{"label":"","small":""}},[_c('span',{staticStyle:{"font-size":"11px"},on:{"click":function($event){return _vm.openModalSetPoint('pretrip')}}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('dashboard.configuration.pretrip'))+" "+_vm._s(_vm.tempP1)+" "),_c('v-icon',{style:({
                    color: _vm.theme === 'dark' ? 'white !important' : '',
                    margin: '0.2em',
                  })},[_vm._v("mdi-pencil-outline")])],1)])]),_c('v-divider',{staticClass:"py-2 mx-6 mt-3",staticStyle:{"border-color":"#607d8b"}})],1):_vm._e(),_vm._l((_vm.getTemps),function([tempKey, value]){return _c('v-row',{key:tempKey,staticClass:"justify-space-around"},[_c('v-chip',{staticClass:"justify-center ma-1 white--text blue-grey lighten-1",staticStyle:{"width":"50%"},attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t(`dashboard.details.${tempKey}`))+" ")]),_c('v-chip',{staticClass:"justify-center ma-1 blue-grey lighten-4",staticStyle:{"width":"35%"},attrs:{"label":"","small":""}},[(tempKey == 'temp_setpoint')?_c('span',{on:{"click":_vm.openModalSetPoint}},[_c('div',[(value)?_c('span',[_vm._v(_vm._s(value)+"ºC")]):_c('span',[_vm._v(" -- ")])])]):_vm._e(),(tempKey !== 'temp_setpoint' && value)?_c('span',[_vm._v(_vm._s(value)+"ºC ")]):_vm._e(),(tempKey !== 'temp_setpoint' && !value)?_c('span',[_vm._v(" -- ")]):_vm._e()])],1)})],2)],1):_vm._e(),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"}},[_c('v-divider',{staticClass:"py-1 mx-2",staticStyle:{"border-color":"#607d8b"}}),_c('v-chip',{attrs:{"label":"","small":"","color":_vm.getMachineStatus ? 'green' : 'grey'}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.getMachineStatus ? _vm.$t('dashboard.details.on') : _vm.$t('dashboard.details.off'))+" ")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }