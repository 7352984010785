const PetitionError = require('../../petitionError');
const { getAnonymous } = require('../../httpRequester');

class GetFramesForLiveFrigoMachineException extends PetitionError {
  constructor(code) {
    super('Get Frames For Live Frigo Machine', code);
  }
}
async function getFramesForLiveFrigoMachine(device_sn, token) {
  const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const response = await getAnonymous(
    `devices/get_frames_for_live_frigo_machine?device_sn=${device_sn}&timezone_offset=${timezoneOffset}&token=${token}`,
  );

  if (response.result !== GetFramesForLiveFrigoMachineException.success) {
    throw new GetFramesForLiveFrigoMachineException(response.result);
  } else {
    return response.frigoMachine;
  }
}

module.exports = { getFramesForLiveFrigoMachine, GetFramesForLiveFrigoMachineException };
