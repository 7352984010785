<template>
  <v-form ref="userForm" v-model="formValid" lazy-validation>
    <v-autocomplete
      :items="devicesToChoose"
      @change="addDevice"
      item-text="device_sn"
      return-object
      outlined
      autofocus
      v-bind:label="$t('user.form.add_device')"
      class="user-search rounded-lg mt-0 mb-0"
      :loading="isLoadingDevices"
      clearable>
    </v-autocomplete>
    <div id="user-data-form-container" class="mt-0">
      <div class="device-data-form-table">
        <v-data-table
          :headers="devicesTableHeader"
          :items="devicesTableData"
          hide-default-footer
          :loading="isTableLoading"
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          class="elevation-1 ma-4"
          @contextmenu:row="editDeviceCtrl"
          style="overflow: auto; min-height: 100px; max-height: 400px">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="icon-user-data-form mr-1" color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:[`item.device_sn`]="{ item }">
            <a href="#" @click="editDevice(item)">
              {{ item?.device_sn }}
            </a>
          </template>
          <template v-slot:item.alias="{ item, index }">
            <a
              :dark="theme === 'dark'"
              :style="{
                color: theme === 'dark' ? 'white !important' : 'rgb(0,0,0)',
                'font-style': 'italic',
              }"
              @click="editAlias(item?.alias, item?.device_sn)">
              {{ item?.alias }}
              <v-icon>mdi-pencil-outline</v-icon>
            </a>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </div>

      <div class="expansion-panel-edit-data">
        <v-expansion-panels class="card-data expansion-panels">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col>
                    {{ $t('device.title') }}
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <div id="form-edit-data-user-cambiar">
                <v-row class="edit-data-user mt-2">
                  <v-col class="edit-data-left">
                    <label class="font-weight-black float-left">{{ $t('user.atribute.name') }}*</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field dense outlined class="rounded-lg" type="text" v-model="name" :rules="textRules" required>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="forCompany == 0" class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.company') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-autocomplete
                      :items="companies"
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      v-model="selectedCompany"
                      class="rounded-lg"
                      :loading="isLoadingCompanies"></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.email') }}*</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field dense outlined class="rounded-lg" type="text" v-model="email" :rules="emailRules">
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.notification_emails') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field
                      :disabled="maxNotificationEmails"
                      dense
                      outlined
                      class="rounded-lg"
                      type="text"
                      v-bind:label="$t('user.notification_emails.mails')"
                      v-model="notificationEmail"
                      :rules="emailRules"
                      append-icon="mdi-plus"
                      @click:append="addNotificationEmail()">
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <v-list-item v-for="(item, i) in notificationsEmails" :key="i">
                      <v-list-item-title v-text="item"></v-list-item-title>
                      <v-icon color="red" @click="deleteEmail(i)">mdi-delete</v-icon>
                    </v-list-item>
                  </v-col>
                </v-row>

                <Confirm ref="confirm"></Confirm>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="font-weight-black float-left">{{ $t('user.atribute.phone') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field
                      dense
                      :rules="phoneRules"
                      oninput="value=value.replace(/^[0-9]{10}$|^[-]$/, '')"
                      outlined
                      class="rounded-lg"
                      type="number"
                      v-model.number="phone">
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="!editingUser" class="edit-data-user">
                  <v-col cols="3">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.password.name') }}*</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      dense
                      outlined
                      readonly
                      class="rounded-lg"
                      type="password"
                      v-model="email"
                      hint="Al menos 6 carácteres"
                      :rules="passwordRules"
                      required
                      autocomplete="new-password">
                    </v-text-field>
                    <h5 style="opacity: 0.5">
                      {{ $t('user.atribute.misc.default_password') + ' ' + email }}
                    </h5>
                  </v-col>
                </v-row>

                <Confirm ref="confirm2"></Confirm>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.notes') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field
                      dense
                      outlined
                      counter
                      :maxlength="maxCharacters"
                      :rules="notesRules"
                      class="rounded-lg"
                      type="text"
                      v-model="notes"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.roles.roles') }}</label>
                  </v-col>

                  <v-col class="edit-data-right">
                    <v-select
                      dense
                      outlined
                      item-text="name"
                      item-value="value"
                      v-model="selectedRoles"
                      :items="refreshRoles"
                      v-bind:label="$t('user.form.select_role')"
                      :rules="[(v) => !!v || $t('general.error.empty_value')]"
                      required
                      class="rounded-lg"></v-select>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.platform') }}</label>
                  </v-col>

                  <v-col class="edit-data-right">
                    <v-select
                      dense
                      outlined
                      v-model="selectedPlatform"
                      :items="platformsToChoose"
                      v-bind:label="$t('user.form.select_platform')"
                      :rules="[(v) => !!v || $t('general.error.empty_value')]"
                      required
                      class="rounded-lg"></v-select>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.unit.unit') }}</label>
                  </v-col>

                  <v-col class="edit-data-right">
                    <v-select
                      dense
                      outlined
                      item-text="name"
                      item-value="value"
                      v-model="selectedUnit"
                      :items="refreshUnits"
                      v-bind:label="$t('user.form.select_unit')"
                      :rules="[(v) => !!v || $t('general.error.empty_value')]"
                      required
                      class="rounded-lg"></v-select>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="mt-2 font-weight-black float-left">{{ $t('user.atribute.restrictions.restrictions') }}</label>
                  </v-col>

                  <v-col class="edit-data-right">
                    <v-select
                      dense
                      outlined
                      multiple
                      item-text="name"
                      item-value="value"
                      v-model="selectedRestrictions"
                      :items="refreshRestrictions"
                      v-bind:label="$t('user.form.select_restrictions')"
                      class="rounded-lg">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <label class="font-weight-black float-left">{{ $t('user.notification_emails.max_clients') }}</label>
                  </v-col>
                  <v-col class="edit-data-right">
                    <v-text-field dense outlined class="rounded-lg" type="number" v-model.number="maxClients"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="edit-data-user">
                  <v-col class="edit-data-left">
                    <v-checkbox
                      v-model="restricted"
                      v-bind:label="$t('user.atribute.access')"
                      class="delete-bgc-option"
                      @change="restrictAccess"></v-checkbox>
                    <h5 class="remark">{{ $t('user.form.restrict_warning') }}</h5>
                    <h5 style="opacity: 0.5">{{ $t('user.form.restrict_explanation') }}</h5>
                    <h5 style="opacity: 0.5">
                      {{ $t('user.form.restrict_administrative') }}
                    </h5>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <template>
      <div class="icon-table-edit">
        <v-row justify="center">
          <v-dialog v-model="dialog" scrollable max-width="1400px">
            <v-card v-show="dialog" class="dialog-style">
              <v-card-title class="dialog">{{ $t('user.configuration.change_carplate') }}</v-card-title>
              <v-container id="container-edit-device" style="padding-top: 0px; padding-bottom: 0px">
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="alias"
                    id="Input"
                    append-icon="mdi-border-color"
                    class="font-weight-black"
                    :hint="$t('user.configuration.add_name')"
                    :rules="maxNameLengthRule"
                    autofocus
                    @keydown.enter="saveAlias">
                  </v-text-field>
                </v-col>
                <v-card-actions>
                  <v-btn color="blue darken-1" text @click="cancelDialog">
                    {{ $t('general.dialog.confirmation.button.cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="saveAlias" :disabled="inputRule">
                    {{ this.$t('dashboard.configuration.accept') }}
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-dialog>
        </v-row>
        <br />
      </div>
    </template>
  </v-form>
</template>

<script>
import { getAllCompanies, getAllDevices, getDevice } from '../../../viewModels/adminViewModel';
import Confirm from '../../../components/confirm.vue';
import router from '../../../plugins/router/index';
import { getUser } from '../../../viewModels/userViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';
import { getUserDevicesForAdmin } from '../../../server/petitions/users/getUserDevices';

export default {
  components: {
    Confirm,
  },

  props: {
    forCompany: {
      type: String,
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
    editingUser: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    theme: ls.get('theme') ?? 'ligth',
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    user: null,
    selectedRoles: instance.userData.type,
    selectedPlatform: instance.userData.platform,
    selectedUnit: instance.userData.unit,
    selectedRestrictions: [],
    platformsToChoose: [],
    devicesToChoose: [],
    devices: [],
    isLoadingDevices: true,
    formValid: false,
    inputRule: false,
    devicesToAdd: [],
    devicesToRemove: [],
    name: instance.userData.name,
    companies: [],
    selectedCompany: instance.userData.company_id,
    isLoadingCompanies: true,
    email: instance.userData.email || '',
    firstTime: true,
    notificationEmail: null,
    notificationsEmails: instance.userData.notification_emails || [],
    phone: instance.userData.phone,
    maxClients: instance.userData.restrictions?.features?.max_clients ?? 5,
    password: '',
    restricted: instance.userData.restricted || false,
    notes: instance.userData.notes,
    maxCharacters: 500,
    devicesTableData: [],
    isTableLoading: true,
    administrative: 'Daniel Ordoñez',
    user: null,
    userDevicesIds: [],
    dupleDevice: false,
    dialog: false,
    alias: '',
    device_sn: null,
    userId: '',
    deviceCollection: null,
  }),

  emits: {
    'device-repeat': null,
    'device-no-repeat': null,
    'delete-success': null,
  },

  methods: {
    editDevice: async function (e) {
      const device = await getDevice(e.device_sn);
      router.push({
        name: 'editDevice',
        params: { device_sn: e.device_sn, deviceData: device },
      });
    },
    editAlias: async function (alias, device) {
      this.dialog = true;
      this.alias = alias;
      this.device_sn = device;
    },
    cancelDialog: async function () {
      this.dialog = false;
    },
    saveAlias: async function () {
      this.dialog = false;
      this.alias;
      this.deviceCollection.map((i) => {
        if (i.device_sn == this.device_sn) i.alias = this.alias;
      });
      eventBus.$emit('saveAlias');
    },
    editDeviceCtrl: async function (e, i) {
      e.preventDefault();
      const device = await getDevice(i.item.device_sn);
      let routeData = router.resolve({ name: 'editDevice', params: { device_sn: i.item.device_sn, deviceData: device } });
      window.open(routeData.href, '_blank');
    },
    validateEmail() {
      return this.notificationEmail.includes('@');
    },
    addDevice: function (e) {
      if (this.devices?.includes(e?.device_sn)) {
        this.$emit('device-repeat');
      }

      if (e && !this.devices.includes(e?.device_sn)) {
        this.devices.push(e?.device_sn);
        this.devicesTableData.push(e);
        this.devicesToAdd.push(e?.device_sn);
        let indexToDelete = this.devicesToRemove.indexOf(e?.device_sn);
        this.devicesToRemove.splice(indexToDelete, 1);
        this.$emit('device-no-repeat');
      }
    },

    async deleteEmail(index) {
      if (
        await this.$refs.confirm.open(this.$t('user.form.delete_notification_email'), this.$t('reports.programmed.delete_info'), {
          color: 'red',
        })
      ) {
        this.notificationsEmails.splice(index, 1);
      }
    },

    async restrictAccess() {
      if (this.restricted) {
        if (
          await this.$refs.confirm2.open(this.$t('user.form.restrict_access:'), this.$t('user.form.restrict_info'), {
            color: 'red',
          })
        ) {
        } else {
          this.restricted = false;
        }
      }
    },

    addNotificationEmail: function () {
      if (this.validateEmail()) {
        this.notificationsEmails.push(this.notificationEmail);

        this.notificationEmail = '';
        return;
      }
    },

    getPlatforms: function () {
      return ['apachepro', 'tmb', 'sor'];
    },

    validate: function () {
      return this.$refs.userForm.validate();
    },

    deleteRow: function (item) {
      this.devicesToRemove.push(item.device_sn);
      let itemSn = item.device_sn;

      //DELETE IN DEVICES
      let indexToDeleteDevices = this.devices.indexOf(itemSn);
      this.devices.splice(indexToDeleteDevices, 1);

      //DELETE IN DEVICES-TABLE-DATA
      let indexToDelete = this.devicesTableData.indexOf(item);
      this.devicesTableData.splice(indexToDelete, 1);

      this.$emit('delete-success');
    },
  },

  mounted: async function () {
    this.selectedRestrictions = [];
    for (const key in this.userData.restrictions) {
      if (Object.hasOwnProperty.call(this.userData.restrictions, key)) {
        const element = this.userData.restrictions[key];

        if (element.can_login == false) this.selectedRestrictions.push('can_login');
        if (element.map_screen == false) this.selectedRestrictions.push('map_screen');
        if (element.temperature_screen == false) this.selectedRestrictions.push('temperature_screen');
        if (element.make_reports == false) this.selectedRestrictions.push('make_reports');
        if (element.change_setpoint == true) this.selectedRestrictions.push('change_setpoint');
        if (element.stop_vehicle == true) this.selectedRestrictions.push('stop_vehicle');
        if (element.telegram_notifications == true) this.selectedRestrictions.push('telegram_notifications');
        if (element.app_pro == true) this.selectedRestrictions.push('app_pro');
      }
    }

    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.devicesToChoose = await getAllDevices();
    this.isLoadingDevices = false;
    this.user = await getUser();
    this.userId = this.userData._id;

    await this.getUserDevicesTable;
    if (this.userData.devices) {
      this.devicesTableData = await this.getUserDevicesTable;
      for (let userDevice of this.devicesTableData) {
        this.devices.push(userDevice.device_sn);
      }
    }
    this.companies = await getAllCompanies();
    this.isLoadingCompanies = false;
    this.platformsToChoose = this.getPlatforms();
    if (!this.selectedUnit) {
      this.selectedUnit = 'metric';
    }
    if (!this.selectedRoles) {
      this.selectedRoles = 'user';
    }
    if (!this.selectedPlatform) {
      this.selectedPlatform = process.env.VUE_APP_PLATFORM;
    }
    this.isTableLoading = false;

    this.userDevicesIds = this.user.devices.map((device) => device.device_id);
  },

  computed: {
    async getUserDevicesTable() {
      if (this.userId) {
        this.deviceCollection = await getUserDevicesForAdmin(this.userData._id, this.userData.token);
        return this.deviceCollection;
      }
    },
    maxNameLengthRule() {
      const regex = /^(?!.*[~˜])[a-zA-Z0-9-\s]{1,20}$/;

      return [
        (value) => regex.test(value) || this.$t('dashboard.configuration.alphabet_character_rule'),
        (value) => (this.inputRule = !regex.test(value)),
      ];
    },
    maxNotificationEmails() {
      return this.notificationsEmails.length >= this.userData?.restrictions?.features?.max_clients;
    },

    devicesTableHeader() {
      return [
        {
          text: this.$t('device.atribute.serial_number'),
          value: 'device_sn',
        },
        { text: this.$t('device.atribute.carplate'), value: 'alias' },
        { text: this.$t('device.atribute.key'), value: 'password' },
        { text: this.$t('device.atribute.notes'), value: 'notes' },
        { text: '', value: 'actions' },
      ];
    },

    passwordRules() {
      return [
        (value) => !!value || this.$t('user.password.error.empty_password'),
        (value) => (value && value.length >= 6) || this.$t('user.password.error.invalid_password'),
      ];
    },

    textRules() {
      return [
        (value) => !!value || this.$t('general.error.empty_value'),
        (value) => (value && value.length <= 45) || this.$t('user.atribute.misc.name_rules'),
      ];
    },

    emailRules() {
      return [
        (value) => {
          if (value) return (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email');
          else return true;
        },
      ];
    },

    phoneRules() {
      return [
        (value) => {
          if (value) return !isNaN(parseFloat(value)) || this.$t('general.error.must_be_number');
          else return true;
        },
      ];
    },

    refreshRestrictions() {
      return [
        { name: this.$t('user.atribute.restrictions.login'), value: 'can_login' },
        { name: this.$t('user.atribute.restrictions.map'), value: 'map_screen' },
        { name: this.$t('user.atribute.restrictions.temp'), value: 'temperature_screen' },
        {
          name: this.$t('user.atribute.restrictions.reports'),
          value: 'make_reports',
        },
        { name: this.$t('user.atribute.restrictions.cold_machine'), value: 'change_setpoint' },
        { name: this.$t('user.atribute.restrictions.immobilise_vehicle'), value: 'stop_vehicle' },
        { name: this.$t('user.atribute.restrictions.telegram'), value: 'telegram_notifications' },
        { name: this.$t('user.atribute.restrictions.app_pro'), value: 'app_pro' },
      ];
    },
    refreshUnits() {
      return [
        { name: this.$t('user.atribute.unit.metric'), value: 'metric' },
        { name: this.$t('user.atribute.unit.imperial'), value: 'imperial' },
      ];
    },

    refreshRoles() {
      return [
        { name: this.$t('user.atribute.roles.admin'), value: 'admin' },
        { name: this.$t('user.atribute.roles.user'), value: 'user' },
      ];
    },

    defaultUnit() {
      return [(value) => !!value || (this.selectedUnit = this.$t('user.atribute.unit.metric'))];
    },

    notificationEmailsRules() {
      return [
        (value) => {
          if (value) return (!!value && /.+@.+/.test(value)) || this.$t('general.error.not_valid_email');
          else return true;
        },
      ];
    },

    notesRules() {
      return [
        (value) => {
          if (value) return value.length <= 499 || this.$t('user.atribute.misc.notes_limit');
          else return true;
        },
      ];
    },
  },
};
</script>

<style>
.remark {
  color: red;
}
</style>
