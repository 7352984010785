<template>
  <div>
    <Navbar @showDeviceList="showDeviceList" :class="showBanner ? 'navbar-with-banner' : ''"></Navbar>
    <v-banner shaped v-if="showBanner" class="fixed-banner">
      {{ computedBannerMessage }}
      <v-icon slot="icon" color="warning" size="22"> mdi-calendar-clock </v-icon>
      <v-icon @click="closeBanner" size="22" class="close-icon"> mdi-close</v-icon>
    </v-banner>
    <v-dialog v-model="showMobilePopup" fullscreen transition="dialog-bottom-transition">
      <v-card class="d-flex flex-column justify-start align-center image-background" style="height: 100%">
        <div class="text-center pt-8">
          <v-icon color="purple" size="116" class="custom-new-icon mb-1"> mdi-new-box </v-icon>
          <h2 class="font-weight-bold mb-6" style="color: black">{{ $t('general.dialog.updates.access_new_app') }}</h2>
        </div>
        <div class="flex-grow-1"></div>
        <div class="text-center pb-8" style="width: 100%">
          <a
            class="mb-4 d-block"
            :href="redirectTo"
            target="_blank"
            style="text-decoration: none; font-size: 20px; font-weight: bold; color: blue; margin-top: 20px">
            {{ isIos ? $t('general.dialog.updates.access_ios_app') : $t('general.dialog.updates.access_android_app') }}
          </a>
          <v-btn @click="closeMobilePopup" color="primary" class="mt-4">{{
            $t('general.dialog.updates.staying_on_the_web')
          }}</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <div v-if="firstTime"><ChangePassword /></div>
    <div v-if="restricted"><UserRestricted /></div>
    <UpdatesDialog :isActive="updatesDialog" v-on:accept-dialog="acceptDialog"> </UpdatesDialog>
    <v-main id="container-general-dashboard" style="overflow: hidden">
      <router-view ref="dashboard"></router-view>
      <Footer></Footer>
    </v-main>
  </div>
</template>

<script>
import ChangePassword from './views/changePassword.vue';
import UserRestricted from './views/userRestricted.vue';
import Navbar from './components/navBar/navbar.vue';
import userviewModel from './viewModels/userViewModel';
import BrokerConnection from './server/brokerConnection/brokerConnection';
import UpdatesDialog from './components/updatesDialog.vue';
import Footer from './components/footer/footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    ChangePassword,
    UserRestricted,
    UpdatesDialog,
    Footer,
  },
  data: () => ({
    firstTime: false,
    updatesDialog: false,
    restricted: false,
    showBanner: false,
    showMobilePopup: false,
    day: '',
    hour: '',
    redirectTo: '',
    isIos: false,
  }),
  methods: {
    setUserDefaultLanguage: function () {
      userviewModel.setLanguageToUserDefault(this);
    },
    showDeviceList: function () {
      this.$refs.dashboard.showDeviceList();
    },
    initializeBrokerConnection: async function () {
      await BrokerConnection.initializeBroker();
    },

    acceptDialog() {
      this.updatesDialog = false;
      userviewModel.markUpdateSeen();
    },
    closeBanner() {
      this.showBanner = false;
    },
    closeMobilePopup() {
      this.showMobilePopup = false;
    },
  },

  async mounted() {
    const user = await userviewModel.getUser();
    if (user.firstTime) {
      this.firstTime = true;
      this.updatesDialog = false;
    }

    if (user.updating_system?.is_update) {
      this.day = user.updating_system.day;
      this.hour = user.updating_system.hour;
      this.showBanner = true;
    }

    if (user.restricted) {
      this.restricted = true;
    } else {
      this.updatesDialog = user.update_seen ? false : true;
    }

    if (this.isMobile) {
      this.showMobilePopup = true;
    }
    //
    this.setUserDefaultLanguage();
    //this.initializeBrokerConnection();

    if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
      this.isIos = true;
      this.redirectTo = process.env.VUE_APP_IOS_REDIRECT;
    } else {
      this.redirectTo = process.env.VUE_APP_ANDROID_REDIRECT;
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    computedBannerMessage() {
      return this.$t('general.next_maintenance', {
        day: this.day,
        hour: this.hour,
      });
    },
  },
};
</script>

<style>
.navbar-with-banner {
  position: fixed !important;
  top: 27px !important;
}

.fixed-banner {
  position: fixed;
  top: -20px;
  z-index: 1000;
  width: 100% !important;
  background-color: transparent !important;
}

.close-icon {
  position: fixed !important;
  right: 5px !important;
  cursor: pointer;
}

.image-background {
  background-image: url('/assets/cold_machine.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-new-icon {
  transform: scaleX(1.1) scaleY(0.8);
  display: inline-block;
}
</style>
