<template>
  <v-dialog
    v-model="isActive"
    max-width="35rem"
    @click:outside="cancelDialog"
    @keydown.esc="cancelDialog"
    class="ticket-view-modal">
    <v-card class="ticket-view-card" color="white-grey darken-1" :loading="isUpdating">
      <div style="display: flex; justify-content: center">
        <v-card-title color="primary" class="text-h5 font-weight-black"
          >{{
            maintenance && maintenance.isRenew
              ? $t('dashboard.device_card.renew_task').toUpperCase()
              : maintenance && !maintenance.isRenew
              ? $t('dashboard.device_card.edit_task').toUpperCase()
              : $t('dashboard.device_card.new_task').toUpperCase()
          }}
        </v-card-title>
      </div>
      <template v-slot:progress>
        <v-progress-linear absolute color="green lighten-3" height="4" indeterminate></v-progress-linear>
      </template>
      <v-form>
        <v-container>
          <v-row>
            <v-col :cols="!isMobile ? 6 : selectedTask == $t('dashboard.device_card.certificates') ? 12 : 7">
              <v-select
                :dark="theme === 'dark'"
                v-model="selectedTask"
                dense
                :items="tasks"
                :label="$t('dashboard.device_card.select_task')"
                hide-details
                :disabled="maintenance ? true : false"
                single-line>
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    class="mb-1"
                    small
                    :style="{
                      'background-color': theme === 'dark' ? '#808080 !important' : '',
                    }"
                    v-if="index === 0">
                    <span>{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>

            <v-col :cols="!isMobile ? 6 : 5">
              <v-text-field
                v-if="selectedTask !== $t('dashboard.device_card.certificates')"
                :dark="theme === 'dark'"
                dense
                v-model="brand"
                :disabled="isUpdating"
                color="blue-grey lighten-2"
                :label="$t('dashboard.device_card.brand')"
                :rules="maxLengthRuleBrand" />
            </v-col>
          </v-row>

          <v-row
            v-if="
              selectedTask !==
              $t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
                $t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase()
            ">
            <v-col :cols="!isMobile ? 6 : 12">
              <v-select
                :dark="theme === 'dark'"
                v-if="selectedTask === $t('dashboard.device_card.certificates')"
                :items="nameCerts"
                prepend-icon="mdi-text"
                v-model="name"
                color="blue-grey lighten-2"
                :label="$t('dashboard.device_card.select_name')"
                dense />
              <v-text-field
                v-else
                :dark="theme === 'dark'"
                dense
                v-model="name"
                :disabled="isUpdating"
                color="blue-grey lighten-2"
                :label="$t('dashboard.device_card.name_task')"
                :rules="maxLengthRuleName" />
            </v-col>

            <v-col :cols="!isMobile ? 6 : 8">
              <v-menu
                class="nofrigoMeasures"
                :dark="theme === 'dark'"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip, attrs }">
                      <v-btn v-on="{ ...tooltip, ...menu }" :style="{ left: '50%', transform: 'translateX(-50%)' }" light icon>
                        <v-text-field
                          :dark="theme === 'dark'"
                          v-model="dateFormatted"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"></v-text-field>
                      </v-btn>
                    </template>
                    <span v-if="selectedTask === $t('dashboard.device_card.certificates')">{{
                      $t('dashboard.device_card.last_certificate')
                    }}</span>
                    <span v-else>{{ $t('dashboard.device_card.last_itv') }}</span>
                  </v-tooltip>
                </template>
                <v-date-picker
                  :dark="theme === 'dark'"
                  elevation="2"
                  show-adjacent-months
                  color="primary"
                  class="ticket-calendar-picker"
                  v-model="date"
                  no-title
                  scrollable
                  :events="calendarEvents"
                  event-color="green lighten-1"
                  @input="$refs.menu.save(date)">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row
            v-if="
              selectedTask ===
              $t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
                $t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase()
            ">
            <v-col :cols="!isMobile ? 3 : 6">
              <v-text-field
                :dark="theme === 'dark'"
                dense
                v-model="name"
                :disabled="isUpdating"
                color="blue-grey lighten-2"
                :label="$t('dashboard.device_card.name_task')"
                :rules="maxLengthRuleName" />
            </v-col>

            <v-col :cols="!isMobile ? 4 : 6">
              <v-text-field
                :dark="theme === 'dark'"
                dense
                class="rounded-lg"
                :label="$t('dashboard.device_card.last_revision')"
                type="number"
                v-model="lastInspectionHours">
              </v-text-field>
            </v-col>

            <v-col :cols="!isMobile ? 5 : 8">
              <v-menu
                class="frigoMeasures"
                :dark="theme === 'dark'"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip, attrs }">
                      <v-btn v-on="{ ...tooltip, ...menu }" :style="{ left: '50%', transform: 'translateX(-50%)' }" light icon>
                        <v-text-field
                          :dark="theme === 'dark'"
                          v-model="dateFormatted"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"></v-text-field>
                      </v-btn>
                    </template>
                    <span>{{ $t('dashboard.device_card.last_frigo') }}</span>
                  </v-tooltip>
                </template>
                <v-date-picker
                  position="sticky"
                  :dark="theme === 'dark'"
                  elevation="2"
                  show-adjacent-months
                  color="primary"
                  class="ticket-calendar-picker"
                  v-model="date"
                  no-title
                  scrollable
                  :events="calendarEvents"
                  event-color="green lighten-1"
                  @input="$refs.menu.save(date)">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-autocomplete
                :dark="theme === 'dark'"
                v-model="devicesModel"
                :disabled="maintenance ? true : false"
                :multiple="selectedTask !== $t('dashboard.device_card.certificates')"
                :items="alias"
                chips
                hide-details
                dense
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                persistent-hint
                :label="$t('alarms.device')">
                <template v-slot:selection="{ item, index }">
                  <v-chip class="ma-1" :style="{ 'background-color': theme === 'dark' ? 'grey' : '' }" v-if="index < 2">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index == 2" class="grey--text text-caption"> (+{{ devicesModel.length - 2 }}) </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                    v-if="selectedTask !== $t('dashboard.device_card.certificates')"
                    ripple
                    @mousedown.prevent
                    :title="$t('general.calendar.select_all')"
                    @click="toggle">
                    <v-list-item-action>
                      <v-icon @click="uncheckAll('all')" :color="devicesModel != null ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> {{ $t('general.calendar.select_all') }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-text-field
                :dark="theme === 'dark'"
                v-if="
                  selectedTask ===
                  $t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
                    $t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase()
                "
                dense
                class="rounded-lg"
                :label="$t('general.form.hours_in_advance')"
                type="number"
                v-model="advanceHours">
              </v-text-field>
              <v-text-field
                :dark="theme === 'dark'"
                v-else
                dense
                class="rounded-lg"
                :label="$t('general.form.days_in_advance')"
                type="number"
                v-model="advanceDays">
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip, attrs }">
                  <div v-on="{ ...tooltip }" light icon>
                    <v-text-field
                      :dark="theme === 'dark'"
                      v-model="maintenancePeriodicity"
                      placeholder="1000"
                      dense
                      v-if="
                        selectedTask ===
                        $t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
                          $t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase()
                      "
                      hide-details
                      type="number"
                      :label="$t('dashboard.device_card.how_often')"
                      color="blue-grey lighten-2"
                      v-on="{ ...tooltip }" />
                    <v-select
                      v-else-if="selectedTask === $t('alarms.Itv_trailer')"
                      :dark="theme === 'dark'"
                      id="periodicityModelSelect"
                      dense
                      v-model="periodicityModel"
                      :disabled="isUpdating"
                      :items="periodicity"
                      :label="$t('dashboard.device_card.how_often')"
                      v-on="{ ...tooltip }" />
                  </div>
                </template>
                <span
                  v-if="
                    selectedTask ===
                    $t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
                      $t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase()
                  "
                  >{{ $t('dashboard.device_card.how_often_hours') }}</span
                >
                <span v-else> {{ $t('dashboard.device_card.how_often_month') }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-if="selectedTask !== $t('dashboard.device_card.certificates')"
                v-model="autoUpdate"
                :disabled="isUpdating"
                class="mt-0"
                color="green lighten-2"
                hide-details>
                <template v-slot:label>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span :style="{ fontSize: isMobile ? '8.8px' : '11px' }" v-on="on">
                          {{ $t('general.form.renew') }}
                        </span>
                      </template>
                      {{ $t('general.form.check_renew') }}
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3"> </v-col>
            <v-col cols="6">
              <v-card
                :disabled="maintenance && !maintenance.isRenew ? true : false"
                v-if="selectedTask === $t('dashboard.device_card.certificates')"
                :style="{
                  'background-color': theme === 'dark' ? '#808080 !important' : '',
                  'align-items': 'center',
                  height: '7.2vh',
                }"
                class="d-flex"
                outlined
                @drop.prevent="onDrop($event)"
                @dragover.prevent="dragover = true"
                @dragenter.prevent="dragover = true"
                @dragleave.prevent="dragover = false">
                <v-file-input
                  :label="$t('general.form.drag_and_drop')"
                  v-model="file"
                  :dark="theme === 'dark' ? true : false"
                  :rules="fileRules"
                  accept="image/png, image/jpeg, application/pdf"
                  @change="customFileValidation(file)"
                  prepend-icon="mdi-cloud-upload" />
              </v-card>
            </v-col>
            <v-col cols="3"> </v-col>
          </v-row>

          <v-row v-if="selectedTask !== $t('dashboard.device_card.certificates')">
            <v-col cols="12">
              <v-text-field
                :dark="theme === 'dark'"
                dense
                v-model="notes"
                :disabled="isUpdating"
                color="blue-grey lighten-2"
                :label="$t('general.form.notes')"
                :rules="maxLengthRuleNotes" />
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-row>
          <v-col cols="6" class="text-md-center" :style="isMobile ? 'display: flex; justify-content: center' : ''">
            <v-btn :loading="isUpdating" small color="red " depressed text @click="cancelDialog">
              <v-icon left>mdi-cancel </v-icon>
              {{ isCreated ? $t('general.form.finish') : $t('general.form.exit') }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-md-center" :style="isMobile ? 'display: flex; justify-content: center' : ''">
            <v-btn
              v-if="maintenance && !maintenance.isRenew"
              outlined
              :disabled="filledButton"
              small
              :loading="isUpdating"
              color="success"
              depressed
              @click="updateDialog">
              <v-icon left> mdi-content-save </v-icon>
              {{ $t('alarms.edit') }}
            </v-btn>
            <v-btn
              v-else-if="maintenance && maintenance.isRenew"
              outlined
              :disabled="filledButton"
              small
              :loading="isUpdating"
              color="success"
              depressed
              @click="updateDialog">
              <v-icon left> mdi-content-save </v-icon>
              {{ $t('dashboard.device_card.renew') }}
            </v-btn>
            <v-btn
              v-else
              outlined
              :disabled="filledButton"
              small
              :loading="isUpdating"
              color="success"
              depressed
              @click="saveDialog">
              <v-icon left> mdi-content-save </v-icon>
              {{ $t('alarms.create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="4000">
      {{ text }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { getUserDevices } from '../../server/petitions/users/getUserDevices';
import { getUser } from '../../viewModels/userViewModel';
const ls = require('localstorage-slim');
import { eventBus } from '../../main';
import { saveCertificate, updateCertificate } from '../../viewModels/devicesViewModel';
import { createMaintenance, getAllUserMaintenance, updateMaintenance } from '../../viewModels/maintenanceViewModel';
const VueI18n = require('../../plugins/lang/index');
import { formatDateTable } from '../../utils/utils';

export default {
  props: {
    deviceData: {
      type: Object,
      default: () => ({}),
    },
    maintenance: {
      type: Object,
      default: null,
    },
  },
  data: (instance) => {
    const originalDate = new Date(instance.maintenance?.nextInspectionDate);
    const adjustedDate = new Date(originalDate.getFullYear() - 2, originalDate.getMonth(), originalDate.getDate() + 1);

    const translationMaintenanceFrigo =
      VueI18n.default.t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
      VueI18n.default.t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase();

    let datePickerFormat;

    if (instance.maintenance?.last_inspection_date) {
      datePickerFormat = formatDateTable(instance.maintenance?.last_inspection_date);
    } else if (instance.maintenance?.nextInspectionDate && !instance.maintenance?.isRenew) {
      datePickerFormat = formatDateTable(adjustedDate.toISOString().substring(0, 10));
    } else if (instance.maintenance?.nextInspectionDate && instance.maintenance?.isRenew) {
      datePickerFormat = formatDateTable(new Date());
    } else {
      datePickerFormat = formatDateTable(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
      );
    }

    const dateDatePicker = (instance) => {
      const { maintenance } = instance;
      if (!maintenance) return new Date().toISOString().slice(0, 10);

      const { last_inspection_date: lastDate, newInspectionDate, nextInspectionDate, isRenew } = maintenance;

      if (lastDate) {
        return lastDate.replaceAll('/', '-');
      } else if (newInspectionDate) {
        return newInspectionDate.toISOString().substring(0, 10).replaceAll('/', '-');
      } else if (nextInspectionDate && !isRenew) {
        return adjustedDate.toISOString().substring(0, 10).replaceAll('/', '-');
      } else {
        return new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10);
      }
    };

    return {
      snackbar: false,
      snackbarColor: 'default',
      text: '',
      theme: ls.get('theme') ?? 'ligth',
      isActive: false,
      autoUpdate: instance.maintenance?.renew ?? false,
      selectedTask:
        instance.maintenance?.type === 'frigo_maintenance'
          ? translationMaintenanceFrigo
          : instance.maintenance?.type === 'ITV'
          ? VueI18n.default.t('alarms.Itv_trailer')
          : VueI18n.default.t('dashboard.device_card.certificates'),
      periodicityModel: instance.maintenance?.itv_periodicity ?? 24,
      maintenancePeriodicity: instance.maintenance?.maintenance_periodicity ?? 100,
      devicesModel: instance.maintenance?.device_sn ? `${instance.maintenance?.alias} ~ ${instance.maintenance?.device_sn}` : [],
      isUpdating: false,
      brand: instance.maintenance?.brand ?? '',
      name: instance.maintenance?.name ?? '',
      tasks: [
        VueI18n.default.t('alarms.Itv_trailer'),
        VueI18n.default.t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
          VueI18n.default.t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase(),
        VueI18n.default.t('dashboard.device_card.certificates'),
      ],
      nameCerts: [
        VueI18n.default.t('dashboard.device_card.termo'),
        VueI18n.default.t('device.last_frames.frame_frigo.p1'),
        VueI18n.default.t('device.last_frames.frame_frigo.p2'),
        VueI18n.default.t('device.last_frames.frame_frigo.p3'),
        VueI18n.default.t('device.last_frames.frame_frigo.p4'),
      ],
      periodicity: [24, 12, 6],
      notes: instance.maintenance?.notes ?? '',
      date: dateDatePicker(instance),
      menu: false,
      lastInspectionHours: instance.maintenance?.last_inspection_hours ?? null,
      advanceDays: instance.maintenance?.days ?? 31,
      advanceHours: instance.maintenance?.hours ?? 100,
      file: null,
      user: null,
      devices: [],
      alias: [],
      isMarked: false,
      inputRule: false,
      dragover: false,
      uploadErrors: [],
      calendarEvents: [],
      filledButton: true,
      isFileValid: true,
      base64: null,
      resFile: null,
      idMaintenance: instance.maintenance?._id ?? null,
      createdAt: instance.maintenance?.created_at ?? null,
      prevState: instance.maintenance?.state ?? null,
      editMaintenance: instance.maintenance ?? null,
      dateFormatted: datePickerFormat,
      isCreated: false,
    };
  },
  methods: {
    formatDateTable,
    formatLastInspectionDateItv(date) {
      const day = new Date(date).getDate().toString().padStart(2, '0');
      const month = (new Date(date).getMonth() + 1).toString().padStart(2, '0'); // +1 porque getMonth() empieza en 0
      const year = new Date(date).getFullYear();

      return `${year}/${month}/${day}`;
    },

    formatDateTableItv(date, periodicity) {
      let month = date.getMonth() + 1 + periodicity; // Sumamos la periodicidad al mes actual
      let year = date.getFullYear();

      // Ajustar meses y años si se pasa de 12
      if (month > 12) {
        let extraYears = Math.floor(month / 12); // Cuántos años extras tenemos
        month = month % 12; // El mes actual después de ajustar los años
        year += extraYears; // Ajustamos el año

        // ¿Y si la suma nos deja en un mes 0? Eso sería diciembre del año anterior
        if (month === 0) {
          month = 12; // Ajustamos a diciembre
          year -= 1; // Retrocedemos un año, porque técnicamente estamos en diciembre del año anterior
        }
      }

      // Formateo final
      const day = date.getDate().toString().padStart(2, '0');
      month = month.toString().padStart(2, '0'); // Asegúrate de que el mes tenga dos dígitos

      return `${year}/${month}/${day}`;
    },

    checkButtonState() {
      if (
        this.name &&
        this.name.length < 11 &&
        this.notes.length < 16 &&
        this.date &&
        this.devicesModel.length > 0 &&
        this.isFileValid
      ) {
        if (
          this.selectedTask ===
          this.$t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
            this.$t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase()
        ) {
          this.lastInspectionHours && this.brand && this.brand.length < 12 && /^[a-zA-Z]*$/.test(this.brand)
            ? (this.filledButton = false)
            : (this.filledButton = true);
        } else if (this.selectedTask === this.$t('alarms.Itv_trailer')) {
          this.brand && this.brand.length < 12 && /^[a-zA-Z]*$/.test(this.brand)
            ? (this.filledButton = false)
            : (this.filledButton = true);
        } else if (this.selectedTask === this.$t('dashboard.device_card.certificates') && !this.file && !this.editMaintenance) {
          this.filledButton = true;
        } else if (this.selectedTask === this.$t('dashboard.device_card.certificates') && this.editMaintenance?.isRenew) {
          this.file ? (this.filledButton = false) : (this.filledButton = true);
        } else {
          this.filledButton = false;
        }
      } else {
        this.filledButton = true;
      }
    },

    async convertBase64(file) {
      const prom = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      return prom;
    },

    async onDrop(event) {
      this.dragover = false;

      if (event.dataTransfer.files.length == 0) {
        return;
      }

      if (event.dataTransfer.files.length > 1) {
        this.uploadErrors.push(this.$t('dashboard.max_files'));
        return;
      }

      const file = event.dataTransfer.files[0];

      this.file = file;
      await this.customFileValidation(file);
    },

    async customFileValidation(value) {
      if (value === null) return (this.isFileValid = true);

      const acceptedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
      const isValidType = value ? acceptedTypes.includes(value.type) : false;
      const isValidSize = value ? value.size < 5000000 : false;

      if (!isValidType) {
        this.isFileValid = false;
        this.$emit('file-changed', this.isFileValid);
        return this.$t('general.error.empty_value');
      }
      if (!isValidSize) {
        this.isFileValid = false;
        this.$emit('file-changed', this.isFileValid);
        return this.$t('general.form.maxSize');
      }

      this.isFileValid = true;
      this.$emit('file-changed', this.isFileValid);
      await this.changeFile();
      return true;
    },

    async changeFile() {
      const file = this.file;
      this.base64 = await this.convertBase64(file);
      this.resFile = this.base64;
    },

    async getSelectedDeviceData() {
      this.devices = await getUserDevices(this.user.token, this.user.userId);
      this.alias = [];
      let isCert = this.selectedTask === this.$t('dashboard.device_card.certificates');
      let isFrigo =
        this.selectedTask ===
        this.$t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
          this.$t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase();

      this.devices.map((i) => {
        if ((isCert && i.certificates <= 4) || !isCert) {
          if ((isFrigo && i.model !== 'tracker') || (!isFrigo && !this.editMaintenance)) {
            return this.alias.push(`${i.alias} ~ ${i.device_sn}`);
          } else if (this.editMaintenance) {
            return this.alias.push(`${this.editMaintenance?.alias} ~ ${this.editMaintenance?.device_sn}`);
          }
        }
      });
    },

    uncheckAll: function (type) {
      if (type == 'all') this.devicesModel = [];
    },

    toggle() {
      this.$nextTick(() => {
        if (this.allDevices || this.isMarked) {
          this.devicesModel = [];
          this.isMarked = false;
        } else {
          this.devicesModel = this.alias;
          this.isMarked = true;
        }
      });
    },

    updateDialog: async function () {
      this.isUpdating = true;

      //-------To get SN devices-----------
      let allDevices = [];
      let deviceSnCert;
      let aliasCert;
      let deviceId;
      if (!this.maintenance) {
        for (let device of this.devicesModel) {
          let deviceSn = Number(device.split('~')[1].trim());
          allDevices.push(deviceSn);
        }
      } else {
        let deviceSn = Number(this.devicesModel.split('~')[1].trim());
        allDevices.push(deviceSn);
      }

      if (this.selectedTask === this.$t('dashboard.device_card.certificates')) {
        this.devices.map((i) => {
          if (i.device_sn == Number(this.devicesModel.split('~')[1].trim())) deviceId = i.deviceId;
        });
        deviceSnCert = Number(this.devicesModel.split('~')[1].trim());
        aliasCert = this.devicesModel.split('~')[0].trim();
      }
      //------------------------------------

      let newInspectionDate = this.formatDateTableItv(new Date(this.date), this.periodicityModel);
      let newInspectionHours = Number(this.lastInspectionHours) + Number(this.maintenancePeriodicity);
      let isItv = this.selectedTask === this.$t('alarms.Itv_trailer');
      let isFrigoMaintenance =
        this.selectedTask ===
        this.$t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
          this.$t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase();
      let isCertificate = this.selectedTask === this.$t('dashboard.device_card.certificates');

      if (isCertificate) {
        let payload = {
          _id: this.idMaintenance,
          device_sn: deviceSnCert,
          new_inspection_date:
            this.instance?.maintenance?.nextInspectionDate && this.instance?.maintenance?.isRenew
              ? this.dateFormatted
              : newInspectionDate,
          name: this.name,
          file: this.resFile,
          days: this.advanceDays,
        };

        let res = await updateCertificate(payload);

        if (res.result === 'SUCCESS') {
          let isCreate = false;
          let updatedCert = res.certificate.at(-1).certificate.filter((i) => i._id == this.idMaintenance);

          updatedCert[0].prevState = this.prevState;
          eventBus.$emit('resultMaintenance', updatedCert[0], isCreate);

          this.snackbar = true;
          this.snackbarColor = 'success';
          this.isCreated = true;
          this.text = this.$t('dashboard.edit_task_success');
        } else {
          this.snackbar = true;
          this.snackbarColor = 'error';
          this.text = this.$t('dashboard.error_edit_task');
        }
      } else {
        let payload = {
          _id: this.idMaintenance ?? null,
          email: this.user.email,
          devices: allDevices,
          name: this.name,
          type: isItv ? 'ITV' : isFrigoMaintenance ? 'frigo_maintenance' : 'Certificate',
          itv_periodicity: isItv ? this.periodicityModel : null,
          maintenance_periodicity: isFrigoMaintenance ? Number(this.maintenancePeriodicity) : null,
          next_inspection_date: isItv ? newInspectionDate : null,
          last_inspection_date: !isCertificate ? this.formatLastInspectionDateItv(new Date(this.date)) : null,
          next_inspection_hours: isFrigoMaintenance ? newInspectionHours : null,
          last_inspection_hours: isFrigoMaintenance ? Number(this.lastInspectionHours) : null,
          notes: isCertificate ? null : this.notes,
          files: isCertificate ? this.file : null,
          days: isItv ? this.advanceDays : null,
          hours: isFrigoMaintenance ? Number(this.advanceHours) : null,
          renew: this.autoUpdate,
          created_at: this.createdAt,
          brand: this.brand,
          telegram_sent: false,
        };

        let res = await updateMaintenance(payload);
        res.response_maintenance[0].prevState = this.prevState;
        if (res.result === 'SUCCESS') {
          let isCreate = false;
          eventBus.$emit('resultMaintenance', res.response_maintenance, isCreate);
          this.snackbar = true;
          this.isCreated = true;
          this.snackbarColor = 'success';
          this.text = this.$t('dashboard.edit_task_success');
        } else {
          this.snackbar = true;
          this.snackbarColor = 'error';
          this.text = this.$t('dashboard.error_edit_task');
        }
      }
      setTimeout(() => {
        this.cancelDialog();
      }, 2200);
    },
    saveDialog: async function () {
      this.isUpdating = true;

      //-------To get SN devices-----------
      let allDevices = [];
      let deviceSnCert;
      let aliasCert;
      let deviceId;
      if (this.selectedTask !== this.$t('dashboard.device_card.certificates')) {
        for (let device of this.devicesModel) {
          let deviceSn = Number(device.split('~')[1].trim());
          allDevices.push(deviceSn);
        }
      } else {
        this.devices.map((i) => {
          if (i.device_sn == Number(this.devicesModel.split('~')[1].trim())) deviceId = i.deviceId;
        });
        deviceSnCert = Number(this.devicesModel.split('~')[1].trim());
        aliasCert = this.devicesModel.split('~')[0].trim();
      }
      //------------------------------------

      let newInspectionDate = this.formatDateTableItv(new Date(this.date), this.periodicityModel);
      let newInspectionHours = Number(this.lastInspectionHours) + Number(this.maintenancePeriodicity);

      let isItv = this.selectedTask === this.$t('alarms.Itv_trailer');
      let isFrigoMaintenance =
        this.selectedTask ===
        this.$t('dashboard.device_card.maintenance_frigo_engine').charAt(0).toUpperCase() +
          this.$t('dashboard.device_card.maintenance_frigo_engine').slice(1).toLowerCase();
      let isCertificate = this.selectedTask === this.$t('dashboard.device_card.certificates');

      if (isCertificate) {
        let res = await saveCertificate(
          this.resFile,
          this.name,
          deviceSnCert,
          newInspectionDate,
          this.advanceDays,
          deviceId,
          aliasCert,
        );
        if (res.result === 'SUCCESS') {
          let isCreate = true;

          eventBus.$emit('resultMaintenance', res.certificate.at(-1), isCreate);

          this.snackbar = true;
          this.snackbarColor = 'success';
          this.isCreated = true;
          this.text = this.$t('dashboard.task_success');
        } else {
          this.snackbar = true;
          this.snackbarColor = 'error';
          this.text = this.$t('dashboard.error_creating_task');
        }
      } else {
        let payload = {
          email: this.user.email,
          devices: allDevices,
          name: this.name,
          type: isItv ? 'ITV' : isFrigoMaintenance ? 'frigo_maintenance' : 'Certificate',
          itvPeriodicity: isItv ? this.periodicityModel : null,
          maintenancePeriodicity: isFrigoMaintenance ? Number(this.maintenancePeriodicity) : null,
          nextInspectionDate: isItv ? newInspectionDate : null,
          lastInspectionDate: !isCertificate ? this.formatLastInspectionDateItv(new Date(this.date)) : null,
          nextInspectionHours: isFrigoMaintenance ? newInspectionHours : null,
          lastInspectionHours: isFrigoMaintenance ? Number(this.lastInspectionHours) : null,
          notes: isCertificate ? null : this.notes,
          files: isCertificate ? this.file : null,
          days: isItv ? this.advanceDays : null,
          hours: isFrigoMaintenance ? this.advanceHours : null,
          renew: this.autoUpdate,
          brand: this.brand,
          telegram_sent: false,
        };

        let res = await createMaintenance(payload);
        if (res.result === 'SUCCESS') {
          let isCreate = true;

          eventBus.$emit('resultMaintenance', res.response_maintenance, isCreate);
          this.snackbar = true;
          this.snackbarColor = 'success';
          this.isCreated = true;
          this.text = this.$t('dashboard.task_success');
        } else {
          this.snackbar = true;
          this.snackbarColor = 'error';
          this.text = this.$t('dashboard.error_creating_task');
        }
      }

      //LIMPIAR EL MODAL UNA VEZ HEMOS CREADO EL MANTENIMIENTO
      setTimeout(() => {
        this.resFile = null;
        this.name = '';
        this.brand = '';
        this.advanceDays = 31;
        this.periodicityModel = 24;
        this.maintenancePeriodicity = 100;
        this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
        this.lastInspectionHours = null;
        this.notes = '';
        this.file = null;
        this.advanceHours = 100;
        this.autoUpdate = false;
        this.devicesModel = [];
      }, 2000);
    },

    cancelDialog: function () {
      this.isActive = false;
      this.$emit('closeMaintenanceDialog');
    },

    setActiveModal: function (active) {
      this.isActive = active;
    },

    resetData: async function () {
      await this.getSelectedDeviceData();

      if (this.selectedTask === this.$t('dashboard.device_card.certificates')) {
        this.name = '';
        this.devicesModel = [];
        this.notes = '';
        this.brand = '';
      }
    },
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },

    name() {
      this.checkButtonState();
    },
    brand() {
      this.checkButtonState();
    },
    notes() {
      this.checkButtonState();
    },
    file() {
      this.checkButtonState();
    },
    devicesModel() {
      this.checkButtonState();
    },
    isFileValid() {
      this.checkButtonState();
    },
    lastInspectionHours() {
      this.checkButtonState();
    },
    selectedTask() {
      this.checkButtonState();
      this.resetData();
    },
    date(val) {
      this.dateFormatted = this.formatDateTable(this.date);
    },
  },

  mounted: async function (e) {
    if (this.maintenance && !this.maintenance.isRenew) this.filledButton = false;

    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });

    this.user = await getUser();
    let maintenances = await getAllUserMaintenance(this.user);
    maintenances.map((i) => {
      if (i.next_inspection_date) {
        this.calendarEvents.push(new Date(i.next_inspection_date).toISOString().substr(0, 10));
      }
    });
    await this.getSelectedDeviceData();
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    maxLengthRuleNotes() {
      return [
        (value) => value.length <= 15 || this.$t('dashboard.device_card.max_15character'),
        (value) => {
          this.inputRule = value.length > 15;
          return true;
        },
        (value) => /^$|[a-zA-Z\-0-9]/.test(value) || this.$t('dashboard.configuration.alphabet_character'),
      ];
    },

    fileRules() {
      return [(value) => !value || value.size < 1000000 || this.$t('general.form.maxSize')];
    },

    maxLengthRuleName() {
      return [
        (value) => value.length <= 10 || this.$t('dashboard.device_card.max_10character'),
        (value) => {
          this.inputRule = value.length > 10;

          return true;
        },
        (value) => /^$|[a-zA-Z\-0-9]/.test(value) || this.$t('dashboard.configuration.alphabet_character'),
      ];
    },

    maxLengthRuleBrand() {
      return [
        (value) => value.length <= 11 || this.$t('dashboard.device_card.max_11character'),
        (value) => {
          this.inputRule = value.length > 11;

          return true;
        },
        (value) => /^[a-zA-Z]*$/.test(value) || this.$t('dashboard.configuration.alphabet_character_brand'),
        // /^$|[a-zA-Z]/.test(value) //Con esta acepta también números entre el texto
      ];
    },

    certsTableHeader() {
      return [
        { text: this.$t('general.form.name_certificate'), value: 'name' },
        { text: this.$t('general.form.certificate'), value: 'certificateUrl' },
        { text: this.$t('general.form.next_inspection'), value: 'nextInspectionDate' },
        { text: '', value: 'actions' },
      ];
    },

    allDevices() {
      if (this.alias) return this.alias?.length === this.devicesModel.length;
    },

    someDevices() {
      if (this.devicesModel) return this.devicesModel?.length > 0 && !this.allDevices;
    },

    icon() {
      if (this.allDevices) return 'mdi-close-box';
      if (this.someDevices) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
};
</script>
<style>
.v-label {
  font-size: small;
}
</style>
