<template>
  <div class="ml-10">
    <div class="user-configuration-container">
      <div class="user-configuration-form-title">
        <h1 class="my-5">
          <v-icon
            @click="back"
            class="mr-1"
            :style="{
              color: theme === 'dark' ? 'white ' : 'black',
            }"
            >mdi-chevron-left</v-icon
          >{{ $t('alarms.information') }}
        </h1>
      </div>
      <div id="container-edit-device">
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.name') }}: </label>
          </v-col>
          <v-col class="edit-data-right">
            <!-- <v-text-field dense outlined class="rounded-lg" type="text" v-model="name"></v-text-field> -->
            <p>{{ name }}</p>
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.alarm_type') }}</label>
          </v-col>
          <v-col class="edit-data-right">
            <p>{{ alarm?.type }}</p>
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.device_sn') }}:</label>
          </v-col>
          <v-col class="edit-data-right">
            <p>{{ alarm?.device_sn }}</p>
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.selected_days') }}: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p v-if="days_selected">{{ days_selected }}</p>
            <p v-else v-for="row in alarm?.selected_days" v-bind:key="row.key">{{ row }}</p>
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.start_day_alarm') }}: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p>{{ alarm?.start_day }}</p>
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.end_day_alarm') }}: </label>
          </v-col>
          <v-col class="edit-data-right"
            ><p>{{ end_date ?? alarm?.end_day }}</p>
          </v-col>
        </v-row>
        <v-row v-if="alarm?.probes_config.p1" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.probe') }} 1: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p>
              Max: {{ alarm?.probes_config.p1.max }} Min: {{ alarm?.probes_config.p1.min }}
              {{ $t('dashboard.configuration.set_point') }}: {{ alarm?.probes_config.p1.set_point }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="alarm?.probes_config.p2" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.probe') }} 2: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p>
              Max: {{ alarm?.probes_config.p2.max }} Min: {{ alarm?.probes_config.p2.min }}
              {{ $t('dashboard.configuration.set_point') }}: {{ alarm?.probes_config.p2.set_point }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="alarm?.probes_config.p3" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.probe') }} 3: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p>
              Max: {{ alarm?.probes_config.p3.max }} Min: {{ alarm?.probes_config.p3.min }}
              {{ $t('dashboard.configuration.set_point') }}: {{ alarm?.probes_config.p3.set_point }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="alarm?.probes_config.p4" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.probe') }} 4: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p>
              Max: {{ alarm?.probes_config.p4.max }} Min: {{ alarm?.probes_config.p4.min }}
              {{ $t('dashboard.configuration.set_point') }}:
              {{ alarm?.probes_config.p4.set_point }}
            </p>
          </v-col>
        </v-row>
        <v-row class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('dashboard.configuration.interval') }}: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p>{{ alarm?.interval }}</p>
          </v-col>
        </v-row>
        <v-row v-if="alarm?.mileage" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            ><label class="font-weight-black float-left">{{ $t('alarms.kilometres') }}: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p>{{ alarm?.mileage }}</p>
          </v-col>
        </v-row>
        <v-row v-if="alarm?.frigo" class="edit-data-user edit-data-device-serie">
          <v-col class="edit-data-left">
            <label class="font-weight-black float-left">{{ $t('alarms.frigo_hours_maintenance') }}: </label>
          </v-col>
          <v-col class="edit-data-right">
            <p>{{ alarm?.frigo }}</p></v-col
          >
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../../plugins/router/index';
const VueI18n = require('../../../plugins/lang/index');
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  props: {
    alarm: {
      type: Object,
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    name: '',
    days_selected: null,
    end_date: null,
    for_monday_to_friday: [
      VueI18n.default.t('alarms.monday'),
      VueI18n.default.t('alarms.tuesday'),
      VueI18n.default.t('alarms.wednesday'),
      VueI18n.default.t('alarms.thursday'),
      VueI18n.default.t('alarms.friday'),
    ],
  }),
  methods: {
    back() {
      router.push({
        path: '/configuration/alarms',
      });
    },
  },
  computed: {},

  mounted: async function () {
    if (!this.alarm)
      router.push({
        name: 'alarm',
      });
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    if (this.alarm?.type == 'temp') {
      this.alarm.type = 'Temperatura';
    }
    this.name = this.alarm?.name;

    const isMondayToFriday =
      this.alarm?.selected_days.length === this.for_monday_to_friday.length &&
      this.alarm?.selected_days.every((day, index) => day === this.for_monday_to_friday[index]);

    if (this.alarm?.selected_days.length == 7) {
      this.days_selected = this.$t('alarms.all_days');
    } else if (isMondayToFriday) {
      this.days_selected = this.$t('alarms.monday_to_friday');
    } else {
      this.days_selected = this.alarm?.selected_days.join(', ');
    }

    if (this.alarm?.end_day.split('/')[3] == '2099') this.end_date = this.$t('alarms.always');
  },
};
</script>
