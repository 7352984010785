<template>
  <v-container v-if="!isMobile" class="shared-container-desktop pa-2">
    <v-row class="shared-content">
      <v-col cols="2" class="menu-shared">
        <v-navigation-drawer permanent class="panel-left-shared">
          <div class="mb-5" style="display: flex; align-items: center; justify-content: center">
            <v-btn @click="createMaintenance" color="primary">{{ $t('dashboard.device_card.create_task') }}</v-btn>
            <MaintenanceModal v-if="isActive" ref="modalTask" @closeMaintenanceDialog="isActive = false" />
          </div>
          <!-- <v-toolbar-title class="mb-5" style="margin-top: 3rem; margin-left: 0.5em"></v-toolbar-title> -->
          <v-divider></v-divider>

          <v-tabs vertical class="tab-vertical" center-active>
            <v-list>
              <v-subheader
                :style="{
                  color: theme === 'dark' ? 'white' : '',
                  'font-weight': 'bold',
                  display: 'flex',
                  'justify-content': 'center',
                }"
                >{{ $t('navbar.frigo_maintenances') }}
              </v-subheader>

              <v-subheader
                :style="{
                  color: theme === 'dark' ? 'white' : '',
                  display: 'flex',
                  'justify-content': 'flex-end ',
                  'font-style': 'italic',
                }"
                class="mr-4"
                >{{ $t('dashboard.device_card.total') }}
              </v-subheader>

              <v-tab to="/maintenance/certs" class="panel-left-btn d-flex justify-space-between">
                <v-icon small class="pr-3">mdi-text-box-multiple-outline</v-icon>
                <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
                  <span style="font-size: xx-small; font-weight: bold">Certs</span>
                  <span style="color: red !important"> {{ certExpired.length }}</span>

                  <span style="color: orange !important"> {{ certNearby.length }}</span>
                  <div style="position: relative; display: inline-flex; justify-content: center; align-items: center">
                    <v-progress-circular class="ml-2" :rotate="360" color="red" :size="30" :width="5" :value="100" />

                    <v-progress-circular
                      class="ml-2"
                      :rotate="360"
                      color="orange"
                      :size="30"
                      :width="5"
                      :value="certNextPercentage"
                      style="position: absolute" />

                    <v-progress-circular
                      class="ml-2"
                      :rotate="360"
                      color="green"
                      :size="30"
                      :width="5"
                      :value="certPercentage"
                      style="position: absolute">
                      {{ certTotal }}
                    </v-progress-circular>
                  </div>
                </div>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <v-tab to="/maintenance/itv" class="panel-left-btn d-flex justify-space-between">
                <v-icon small class="pr-3">mdi-calendar</v-icon>
                <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
                  <span style="font-size: xx-small; font-weight: bold">ITV</span>
                  <span style="color: red !important"> {{ itvExpired.length }}</span>

                  <span style="color: orange !important"> {{ itvNearby.length }}</span>

                  <div style="position: relative; display: inline-flex; justify-content: center; align-items: center">
                    <v-progress-circular class="ml-2" :rotate="360" color="red" :size="30" :width="5" :value="100" />

                    <v-progress-circular
                      class="ml-2"
                      :rotate="360"
                      color="orange"
                      :size="30"
                      :width="5"
                      :value="itvNextPercentage"
                      style="position: absolute" />

                    <v-progress-circular
                      class="ml-2"
                      :rotate="360"
                      color="green"
                      :size="30"
                      :width="5"
                      :value="itvPercentage"
                      style="position: absolute">
                      {{ itvTotal }}
                    </v-progress-circular>
                  </div>
                </div>

                <v-icon small>mdi-chevron-right</v-icon>
              </v-tab>
              <v-divider></v-divider>
              <v-tab to="/maintenance/frigo_machine" class="panel-left-btn d-flex justify-space-between">
                <v-icon small class="pr-3">mdi-wrench</v-icon>
                <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
                  <span style="font-size: xx-small; font-weight: bold">Frigo</span>

                  <span style="color: red !important"> {{ frigoExpired.length }}</span>

                  <span style="color: orange !important"> {{ frigoNearby.length }}</span>

                  <div style="position: relative; display: inline-flex; justify-content: center; align-items: center">
                    <v-progress-circular class="ml-2" :rotate="360" color="red" :size="30" :width="5" :value="100" />

                    <v-progress-circular
                      class="ml-2"
                      :rotate="360"
                      color="orange"
                      :size="30"
                      :width="5"
                      :value="frigoNextPercentage"
                      style="position: absolute" />

                    <v-progress-circular
                      class="ml-2"
                      :rotate="360"
                      color="green"
                      :size="30"
                      :width="5"
                      :value="frigoPercentage"
                      style="position: absolute">
                      {{ frigoTotal }}
                    </v-progress-circular>
                  </div>
                </div>

                <v-icon small>mdi-chevron-right</v-icon>
              </v-tab>
            </v-list>
          </v-tabs>
          <v-divider></v-divider>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="10" class="shared-table">
        <router-view style="z-index: -1"></router-view>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else class="shared-container-mobile pa-2 ma-2" style="max-width: 2500px">
    <template>
      <v-card>
        <v-toolbar :dark="theme === 'dark'">
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <span>{{ $t('nav_bar.maintenance') }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <template v-slot:extension>
            <v-tabs centered slider-color="#1976d2 !important">
              <v-tab to="/maintenance/certs" style="font-size: small">
                <v-icon small class="pr-3">mdi-text-box-multiple-outline</v-icon>
                Certs
              </v-tab>
              <v-tab to="/maintenance/itv" style="font-size: small">
                <v-icon small class="pr-3">mdi-calendar</v-icon>
                ITV
              </v-tab>
              <v-tab to="/maintenance/frigo_machine" style="font-size: small">
                <v-icon small class="pr-3">mdi-wrench</v-icon>
                Frigo
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
      </v-card>
    </template>
    <div class="mt-2 mb-2" style="display: flex; align-items: center; justify-content: center">
      <v-btn small @click="createMaintenance" color="primary">{{ $t('dashboard.device_card.create_task') }}</v-btn>
      <MaintenanceModal v-if="isActive" ref="modalTask" @closeMaintenanceDialog="isActive = false" />
    </div>
    <router-view style="z-index: -1" class="shared-content-mobile"></router-view>
  </v-container>
</template>

<script>
import MaintenanceModal from '../../../components/forms/maintenanceModal.vue';
import { getUser } from '../../../viewModels/userViewModel';
import { getAllUserMaintenance } from '../../../viewModels/maintenanceViewModel';
import { updateMaintenanceDates, updateMaintenanceHours } from '../../../utils/utils';
import { eventBus } from '../../../main';
import { getCertificates } from '../../../viewModels/devicesViewModel';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';

const ls = require('localstorage-slim');

export default {
  components: { MaintenanceModal },
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    isActive: false,
    itvTotal: 0,
    frigoTotal: 0,
    certTotal: 0,
    user: null,
    itvCollection: [],
    frigoMaintenanceCollection: [],
    docsCollection: [],
    itvActived: [],
    itvNearby: [],
    itvExpired: [],
    frigoActived: [],
    frigoNearby: [],
    frigoExpired: [],
    certActived: [],
    certNearby: [],
    certExpired: [],
    itvPercentage: 0,
    itvNextPercentage: 0,
    frigoPercentage: 0,
    frigoNextPercentage: 0,
    certPercentage: 0,
    certNextPercentage: 0,
    userDevices: null,
  }),
  mounted: async function (e) {
    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });
    this.user = await getUser();
    this.userDevices = await getUserDevices(this.user.token, this.user.userId);

    let maintenances = await getAllUserMaintenance(this.user);
    if (maintenances != null) {
      this.frigoMaintenanceCollection = maintenances.filter(
        (maintenance) =>
          maintenance.type == 'frigo_maintenance' &&
          this.userDevices.some((device) => device.device_sn === maintenance.device_sn),
      );

      this.itvCollection = maintenances.filter(
        (maintenance) =>
          maintenance.type == 'ITV' && this.userDevices.some((device) => device.device_sn === maintenance.device_sn),
      );
    }

    eventBus.$on('resultMaintenance', async (doc, isCreate) => {
      if (!doc.certificateUrl && doc[0].type === 'ITV') {
        doc.forEach(async (maintenance) => {
          await updateMaintenanceDates(doc);
          this.stateHandler(maintenance, isCreate, this.itvActived, this.itvNearby, this.itvExpired);
        });
      } else if (!doc.certificateUrl && doc[0].type === 'frigo_maintenance') {
        doc.forEach(async (maintenance) => {
          await updateMaintenanceHours(doc); //Hace falta el await???
          this.stateHandler(maintenance, isCreate, this.frigoActived, this.frigoNearby, this.frigoExpired);
        });
      } else {
        await updateMaintenanceDates(doc);
        this.stateHandler(doc, isCreate, this.certActived, this.certNearby, this.certExpired);
      }
    });

    eventBus.$on('removeMaintenance', async (doc) => {
      if (!doc.certificateUrl && doc.type === 'ITV') {
        await updateMaintenanceDates(doc);
        this.removeMaintenanceHandler(doc, this.itvActived, this.itvNearby, this.itvExpired);
      } else if (!doc.certificateUrl && doc.type === 'frigo_maintenance') {
        await updateMaintenanceHours(doc);
        this.removeMaintenanceHandler(doc, this.frigoActived, this.frigoNearby, this.frigoExpired);
      } else {
        await updateMaintenanceDates(doc);
        this.removeMaintenanceHandler(doc, this.certActived, this.certNearby, this.certExpired);
      }
    });

    eventBus.$on('updateMaintenance', async (doc) => {
      if (!doc.certificateUrl && doc.type === 'ITV') {
        if (doc.prevState == 'next') this.itvNearby.pop() && this.itvActived.push(doc.prevState);
        if (doc.prevState == 'expired') this.itvExpired.pop() && this.itvActived.push(doc.prevState);
      } else {
        // } else if (!doc.certificateUrl && doc.type === 'frigo_maintenance') {
        if (doc.prevState == 'next') this.frigoNearby.pop();

        if (doc.prevState == 'expired') this.frigoExpired.pop();

        setTimeout(() => {
          if (doc.state == 'active') this.frigoActived.push(doc.state);

          if (doc.state == 'next') this.frigoNearby.push(doc.state);

          if (doc.state == 'expired') this.frigoExpired.push(doc.state);
        }, 1);
      }

      //----Renovar Certificados---
      // else {
      //   if (doc.prevState == 'next') this.certNearby.pop() && this.certActived.push(doc.prevState);
      //   if (doc.prevState == 'expired') this.certExpired.pop() && this.certActived.push(doc.prevState);
      // }
    });

    //PARA CADA CERTIFICADO
    let deviceDocuments = await getCertificates(this.user.userId);

    deviceDocuments
      .flatMap((i) => i.certificates || []) //desmonto la array anidada y filtro null/undefined
      .filter((c) => c) // filtro falsy values (null, undefined, '', 0, false, NaN)
      .forEach((c) => this.docsCollection.push(c));

    this.docsCollection.forEach((doc, index) => {
      doc.index = index;

      updateMaintenanceDates(doc);
      this.pushMaintenanceHandler(doc, this.certActived, this.certNearby, this.certExpired);
    });

    //PARA CADA ITV
    this.itvCollection.forEach(async (doc, index) => {
      doc.index = index;

      updateMaintenanceDates(doc);
      this.pushMaintenanceHandler(doc, this.itvActived, this.itvNearby, this.itvExpired);
    });

    //FRIGO
    this.frigoMaintenanceCollection.forEach((doc, index) => {
      doc.index = index;

      updateMaintenanceHours(doc);
      this.pushMaintenanceHandler(doc, this.frigoActived, this.frigoNearby, this.frigoExpired);
    });
  },
  methods: {
    stateHandler: function (maintenance, isCreate, maintenanceActived, maintenanceNearby, maintenanceExpired) {
      if (isCreate) {
        maintenance.state == 'active'
          ? maintenanceActived.push(maintenance.state)
          : maintenance.state == 'next'
          ? maintenanceNearby.push(maintenance.state)
          : maintenanceExpired.push(maintenance.state);
      } else {
        if (maintenance.prevState != maintenance.state) {
          maintenance.prevState == 'active'
            ? maintenanceActived.pop()
            : maintenance.prevState == 'next'
            ? maintenanceNearby.pop()
            : maintenanceExpired.pop();

          maintenance.state == 'active'
            ? maintenanceActived.push(maintenance.state)
            : maintenance.state == 'next'
            ? maintenanceNearby.push(maintenance.state)
            : maintenanceExpired.push(maintenance.state);
        }
      }
    },
    pushMaintenanceHandler: function (doc, maintenanceActived, maintenanceNearby, maintenanceExpired) {
      doc.state == 'active'
        ? maintenanceActived.push(doc.state)
        : doc.state == 'next'
        ? maintenanceNearby.push(doc.state)
        : maintenanceExpired.push(doc.state);
    },
    removeMaintenanceHandler: function (doc, maintenanceActived, maintenanceNearby, maintenanceExpired) {
      doc.state == 'active' ? maintenanceActived.pop() : doc.state == 'next' ? maintenanceNearby.pop() : maintenanceExpired.pop();
    },
    calculateTotal: function () {
      this.itvTotal = this.itvActived.length + this.itvNearby.length + this.itvExpired.length;
      this.frigoTotal = this.frigoActived.length + this.frigoNearby.length + this.frigoExpired.length;
      this.certTotal = this.certActived.length + this.certNearby.length + this.certExpired.length;

      this.calculatePercentage(this.itvActived.length, this.frigoActived.length, this.certActived.length, 'actived');
      this.calculatePercentage(
        this.itvActived.length + this.itvNearby.length,
        this.frigoActived.length + this.frigoNearby.length,
        this.certActived.length + this.certNearby.length,
        'nearby',
      );
    },
    calculatePercentage: function (paramsItv, paramsFrigo, paramsCert, state) {
      if (state === 'actived') {
        this.itvPercentage = (paramsItv * 100) / this.itvTotal;
        this.frigoPercentage = (paramsFrigo * 100) / this.frigoTotal;
        this.certPercentage = (paramsCert * 100) / this.certTotal;
      } else {
        this.itvNextPercentage = (paramsItv * 100) / this.itvTotal;
        this.frigoNextPercentage = (paramsFrigo * 100) / this.frigoTotal;
        this.certNextPercentage = (paramsCert * 100) / this.certTotal;
      }
    },

    createMaintenance: function () {
      this.isActive = true;
      setTimeout(() => {
        this.$refs.modalTask.setActiveModal(true);
      }, 100);
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    itvActived() {
      this.calculateTotal();
    },
    itvNearby() {
      this.calculateTotal();
    },
    itvExpired() {
      this.calculateTotal();
    },

    frigoActived() {
      this.calculateTotal();
    },
    frigoNearby() {
      this.calculateTotal();
    },
    frigoExpired() {
      this.calculateTotal();
    },

    certActived() {
      this.calculateTotal();
    },
    certNearby() {
      this.calculateTotal();
    },
    certExpired() {
      this.calculateTotal();
    },
  },
};
</script>
<style>
.v-progress-circular__underlay {
  stroke: none;
}
</style>
