<template>
  <v-container ref="frigoMaintenanceForm" class="shared-container-desktop pa-2">
    <div style="width: 100%" id="device-data-form-container">
      <v-card-title color="primary" class="ticket-view-modal-title headline" style="justify-content: center">
        <div></div>
        <span
          class="ticket-view-modal-title-text text-center"
          :style="{ color: theme === 'dark' ? 'white' : '', 'word-break': 'keep-all' }">
          {{ $t('dashboard.device_card.maintenance_frigo_engine') }}
        </span>
      </v-card-title>
      <div style="margin-top: 5px" class="text-center pt-2"></div>

      <div class="device-data-form-table">
        <v-spacer></v-spacer>
        <v-text-field
          :dark="theme === 'dark'"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('nav_bar.menu.search')"
          single-line
          hide-details></v-text-field>
        <v-data-table
          :disable-sort="isMobile"
          @click:row="editMaintenance"
          hide-default-footer
          sort-by="order"
          :sort-desc="false"
          :search="search"
          :loading="isTableLoading"
          :headers="frigoTableHeader"
          :items="frigoMaintenanceCollection"
          item-key="_id"
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          class="elevation-1">
          <template v-slot:[`item.engine_time`]="{ item }">
            <span> {{ item.engine_time }} h </span>
          </template>

          <template v-slot:[`item.maintenance_periodicity`]="{ item }">
            <span> {{ item.maintenance_periodicity }} h </span>
          </template>

          <template v-slot:[`item.last_inspection_hours`]="{ item }">
            <span> {{ item.last_inspection_hours }} h</span>
          </template>

          <template v-slot:[`item.next_inspection_hours`]="{ item }">
            <span
              v-if="item.state?.toLowerCase() === 'active'"
              class="mdi mdi-circle icon-green pr-2"
              :title="$t('admin.menu.active')">
            </span>
            <span
              v-if="item.state?.toLowerCase() === 'expired'"
              class="mdi mdi-circle icon-red pr-2"
              :title="$t('nav_bar.menu.expired')">
            </span>
            <span
              v-if="item.state?.toLowerCase() === 'next'"
              class="mdi mdi-circle icon-orange pr-2"
              :title="$t('nav_bar.menu.next')">
            </span>
            <span> {{ item.next_inspection_hours }} h </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-row style="display: flex; justify-content: center; align-items: center">
              <!-- <a :href="item.files" target="_blank">
                <v-icon small color="primary">mdi-file-eye</v-icon>
              </a> -->

              <a>
                <v-icon small color="success" @click.stop="renewRow(item)"> mdi-update </v-icon>
              </a>

              <a>
                <v-icon small color="error" @click.stop="deleteRow(item)"> mdi-delete </v-icon>
              </a>
            </v-row>
          </template>
        </v-data-table>

        <div class="text-center pt-2">
          <v-pagination
            :dark="theme === 'dark'"
            :style="{
              color: theme === 'dark' ? 'white !important' : '',
              ' margin-bottom': '20px',
            }"
            v-model="page"
            :length="pageCount"></v-pagination>
        </div>
      </div>
    </div>
    <Confirm ref="confirm"></Confirm>
    <MaintenanceModal v-if="isActive" ref="modalTask" :maintenance="maintenance" @closeMaintenanceDialog="isActive = false" />
  </v-container>
</template>

<script>
import MaintenanceModal from '../../../components/forms/maintenanceModal.vue';
import { getUser } from '../../../viewModels/userViewModel';
import { updateMaintenance, deleteMaintenance, getAllUserMaintenance } from '../../../viewModels/maintenanceViewModel';
import { updateMaintenanceHours, formatLastInspectionDate } from '../../../utils/utils';
import { getUserDevices } from '../../../server/petitions/users/getUserDevices';
import Confirm from '../../../components/confirm.vue';
const ls = require('localstorage-slim');
import { eventBus } from '../../../main';

export default {
  components: { Confirm, MaintenanceModal },
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    theme: ls.get('theme') ?? 'ligth',
    isSmallWindow: window.innerWidth <= 1550 ? true : false,
    windowWidth: window.innerWidth,
    user: null,
    isTableLoading: true,
    page: 1,
    itemsPerPage: window.innerWidth <= 1550 ? 5 : 10,
    frigoMaintenanceCollection: [],
    pageCount: 5,
    page: 1,
    search: '',
    userDevices: null,
    devicesCollection: [],
    isActive: false,
    maintenance: null,
  }),

  mounted: async function () {
    window.addEventListener('resize', this.handleResize);

    this.user = await getUser();
    let maintenances = await getAllUserMaintenance(this.user);
    this.userDevices = await getUserDevices(this.user.token, this.user.userId);

    if (maintenances != null) {
      this.frigoMaintenanceCollection = maintenances.filter(
        (maintenance) =>
          maintenance.type == 'frigo_maintenance' &&
          this.userDevices.some((device) => device.device_sn === maintenance.device_sn),
      );
    }

    this.updateFrigoCollection(); //aqui se le llama

    eventBus.$on('resultMaintenance', (doc, isCreate) => {
      if (!doc.certificateUrl && doc[0].type === 'frigo_maintenance') {
        doc.forEach(async (device) => {
          if (isCreate) {
            this.frigoMaintenanceCollection.push(device);
          } else {
            this.frigoMaintenanceCollection = this.frigoMaintenanceCollection.map((i) => (i._id == device._id ? device : i));
          }
        });
        this.updateFrigoCollection();
      }
    });

    eventBus.$on('changeLanguageEvent', async (lang) => {
      this.updateFrigoCollection();
    });

    eventBus.$on('changeTheme', (theme) => {
      this.theme = theme;
    });

    this.isTableLoading = false;
  },

  methods: {
    editMaintenance: async function (maintenance) {
      this.isActive = true;
      this.maintenance = maintenance;
      setTimeout(() => {
        this.$refs.modalTask.setActiveModal(true);
      }, 100);
    },

    updateFrigoCollection: async function () {
      this.frigoMaintenanceCollection.forEach(async (doc, index) => {
        doc.index = index;
        this.userDevices.filter((i) => {
          if (i.device_sn == doc.device_sn) {
            doc.engine_time = Math.round(i.engine_time);
            doc.alias = i.alias;
          }
        });

        let isRenew = false;
        updateMaintenanceHours(doc, isRenew);
      });

      this.frigoMaintenanceCollection.sort((a, b) => a.order - b.order);
    },

    deleteRow: async function (item) {
      if (
        await this.$refs.confirm.open(
          `${this.$t('general.dialog.confirmation.button.accept_delete')}: ${item.name}`,
          this.$t('general.form.delete_frigo_maintenance'),
          {
            color: 'red',
          },
        )
      ) {
        let indexToDelete = this.frigoMaintenanceCollection.indexOf(item);
        this.frigoMaintenanceCollection.splice(indexToDelete, 1);

        await deleteMaintenance(item);
        eventBus.$emit('removeMaintenance', item);
      }
    },

    renewRow: async function (item) {
      let snToArray = [];
      snToArray.push(item.device_sn);
      item.devices = snToArray;

      item.prevState = item.state;

      if (
        await this.$refs.confirm.open(
          `${this.$t('general.form.renew')}: ${item.name}`,
          ` ${this.$t('general.form.renew_frigo')}`,
          {
            color: 'green',
          },
        )
      ) {
        eventBus.$emit('updateMaintenance', item);

        item.last_inspection_date = formatLastInspectionDate(new Date());
        let isRenew = true;
        updateMaintenanceHours(item, isRenew);
        this.updateMaintenance(item);
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    updateMaintenance: async function (payload) {
      try {
        await updateMaintenance(payload);
      } catch (error) {
        switch (e.code) {
          case CreateMaintenanceException.unknownError:
          case CreateMaintenanceException.incorrectParameters:
          case CreateMaintenanceException.invalidToken:
          case CreateMaintenanceException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
        }
      }
    },
  },

  watch: {
    windowWidth(newWidth, oldWidth) {
      this.isSmallWindow = newWidth <= 1550;
      this.itemsPerPage = this.isSmallWindow ? 5 : 10;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    frigoTableHeader() {
      return [
        {
          text: this.$t('dashboard.device_card.name_task'),
          value: 'name',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '14%' : '10%',
          divider: true,
        },
        {
          text: this.$t('general.form.alias_device'),
          value: 'alias',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '14%' : '8%',
          divider: true,
        },
        {
          text: this.$t('dashboard.device_card.brand'),
          value: 'brand',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '12%' : '8%',
          divider: true,
        },
        {
          text: this.$t('general.form.maintenance_date'),
          value: 'last_inspection_date',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: '1%',
          divider: true,
        },
        {
          text: this.$t('general.form.hours'),
          value: 'engine_time',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: '6.5%',
          divider: true,
        },
        {
          text: this.$t('general.form.every'),
          value: 'maintenance_periodicity',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: '4%',
          divider: true,
        },
        {
          text: this.$t('general.form.last_revision'),
          value: 'last_inspection_hours',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '9%' : '6%',
          divider: true,
        },
        {
          text: this.$t('general.form.next_revision'),
          value: 'next_inspection_hours',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '12%' : '8%',
          divider: true,
        },
        {
          text: this.$t('general.form.validity'),
          value: 'remainingHours',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '10%' : '4%',
          divider: true,
        },
        {
          text: this.$t('general.form.notes'),
          value: 'notes',
          cellClass: 'headerClass',
          class: 'headerClass',
          alignment: 'center',
          width: this.isSmallWindow ? '12%' : '9%',
          divider: true,
        },
        {
          text: '',
          cellClass: 'headerClass',
          class: 'headerClass',
          value: 'actions',
          alignment: 'center',
          width: this.isSmallWindow ? '9%' : '5%',
          divider: true,
        },
      ];
    },
  },
};
</script>

<style scoped>
.headerClass {
  font-size: smaller !important;
  text-align: center;
}
::v-deep .v-icon.notranslate.v-data-table-header__icon {
  display: none;
}
::v-deep .v-pagination__item.v-pagination__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5vh;
}

::v-deep .v-pagination__navigation.v-pagination__navigation {
  height: 2.5vh;
}
::v-deep td.text-start.headerClass.v-data-table__divider {
  font-size: 12px !important;
}
</style>
