<template>
  <v-container class="d-flex justify-center">
    <v-row class="spinner-container d-flex">
      <h3 class="d-flex justify-center" v-if="this.$route.query">
        {{ language.t('loader.loading_message') }}
      </h3>
      <h1 class="d-flex justify-center">{{ language.t('loader.loading_file') }}</h1>
      <h1 v-if="cold_machine" class="d-flex justify-center">
        {{ language.t('loader.carrier_api_data') }}
      </h1>

      <v-row class="d-flex justify-center mt-12">
        <v-progress-circular
          v-if="invalidReport"
          v-show="isLoading"
          color="primary"
          size="70"
          indeterminate></v-progress-circular>

        <v-progress-circular v-if="stopSpin" color="green" size="100" indeterminate></v-progress-circular>
      </v-row>
    </v-row>
    <v-alert class="mt-7" v-if="invalidReport && allowAlert" prominent type="error">
      <v-row align="center">
        <v-col class="grow"> <v-icon>mdi-emoticon-sad</v-icon> {{ language.t('reports.unable_to.create') }} </v-col>
        <v-col class="shrink">
          <v-btn @click="returnToMainPage">{{ language.t('reports.unable_to.create_button') }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert class="mt-7" v-if="noData && allowAlert" prominent type="warning">
      <v-row align="center">
        <v-col class="grow"> <v-icon>mdi-information-variant</v-icon> {{ language.t('reports.noData') }} </v-col>
        <v-col class="shrink">
          <v-btn @click="returnToMainPage">{{ language.t('reports.unable_to.create_button') }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert class="mt-7" v-if="validReport && allowAlert" prominent type="success">
      <v-row align="center">
        <v-col class="grow"> <v-icon>mdi-emoticon-happy</v-icon> {{ language.t('reports.success') }} </v-col>
        <v-col class="shrink">
          <v-btn @click="returnToMainPage">{{ language.t('reports.unable_to.create_button') }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>
<style>
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10rem;
}
</style>
<script>
import { getTicket } from '../viewModels/reportsViewModel';
import { getTemperatureTicket } from '../server/petitions/reports/getTemperatureTicket';

import { getReport, GetReportException } from '../server/petitions/reports/getReport';
import { getToken, getUserByToken } from '../viewModels/userViewModel';
import { getCompany } from '../viewModels/adminViewModel';

const VueI18n = require('../plugins/lang/index');

export default {
  props: {
    data: {
      type: String,
    },
  },
  data: () => ({
    language: null,
    type: '',
    dateFrom: '',
    dateTo: '',
    startingTime: '',
    endingTime: '',
    range: '',
    deviceSn: '',
    carplate: '',
    cif: '',
    model: '',
    assetIdCarrier: '',
    apiKeyCarrier: '',
    invalidReport: false,
    noData: false,
    validReport: false,
    devices: [],
    km_count: false,
    driving_hours: false,
    map_route: false,
    engine_hours: false,
    pi_sum: false,
    poi_delivery: false,
    engine_hours: false,
    temp_graph: false,
    humidity_graph: false,
    journey_sum: false,
    temp_ticket: false,
    humidity_report: false,
    open_doors: false,
    custom: false,
    cold_machine: false,
    isLoading: true,
    allowAlert: false,
    stopSpin: true,
    probes: [],
  }),
  methods: {
    returnToMainPage() {
      window.close();
    },

    activateAlert() {
      setTimeout(() => {
        this.isLoading = false;
        this.allowAlert = true;
      }, 2350);
    },
  },

  computed: {},

  watch: {
    variableName: function (param1, param2) {
      //code
    },
  },

  mounted: async function () {
    this.activateAlert();
    const token = await getToken();
    const user = await getUserByToken(token);
    let company = '';
    VueI18n.default.locale = user.locale;
    this.language = VueI18n.default;
    this.language.locale === 'gb' ? (this.language.locale = 'en') : this.language.locale;
    if (user.companyId == null) {
      company = 'Sin CIF';
    } else {
      company = await getCompany(user.companyId);
    }

    this.type = this.$route.query.type;
    this.dateFrom = this.$route.query.dateFrom;
    this.dateTo = this.$route.query.dateTo;
    this.startingTime = this.$route.query.startingTime;
    this.endingTime = this.$route.query.endingTime;

    if (this.type == 'ticket') {
      this.range = this.$route.query.range;
      this.deviceSn = this.$route.query.deviceSn;
      this.carplate = this.$route.query.carplate;
      this.probes = this.$route.query.probes;
      this.cif = this.$route.query.cif ?? company;
      try {
        await getTemperatureTicket(
          'pdf',
          this.dateFrom,
          this.dateTo,
          this.range,
          this.deviceSn,
          this.carplate,
          this.cif,
          token,
          user.email,
          this.probes,
        );
      } catch (e) {
        if (e.toString() === 'Error: Error in Get Temperature Ticket: NO_DATA') {
          this.noData = true;
          this.stopSpin = false;
          return;
        }
        this.invalidReport = true;
        this.stopSpin = false;

        return;
      }
      this.validReport = true;
    }

    if (this.type == 'report') {
      this.devices_sn = this.$route.query.devices_sn;
      this.probes = this.$route.query.probes;
      this.carplate = this.$route.query.carplate;
      this.name = this.$route.query.name;
      this.model = this.$route.query.model;
      this.apiKeyCarrier = this.$route.query.apiKey;
      this.assetIdCarrier = this.$route.query.assetIdCarrier;
      this.km_count = this.$route.query.km_count == 'true' ? true : false;
      this.driving_hours = this.$route.query.driving_hours == 'true' ? true : false;
      this.map_route = this.$route.query.map_route == 'true' ? true : false;
      this.pi_sum = this.$route.query.pi_sum == 'true' ? true : false;
      this.poi_delivery = this.$route.query.poi_delivery == 'true' ? true : false;
      this.engine_hours = this.$route.query.engine_hours == 'true' ? true : false;
      this.temp_graph = this.$route.query.temp_graph == 'true' ? true : false;
      this.journey_sum = this.$route.query.journey_sum == 'true' ? true : false;
      this.temp_ticket = this.$route.query.temp_report == 'true' ? true : false;
      this.humidity_report = this.$route.query.humidity_report == 'true' ? true : false;
      this.open_doors = this.$route.query.open_doors == 'true' ? true : false;
      this.custom = this.$route.query.custom == 'true' ? true : false;
      this.humidity_graph = this.$route.query.humidity_graph == 'true' ? true : false;
      this.cold_machine = this.$route.query.cold_machine == 'true' ? true : false;

      let devices = [];
      if (typeof this.devices_sn == 'string') {
        devices.push({
          serialNumber: this.devices_sn,
          carplate: this.carplate,
          name: this.name,
          model: this.model,
          assetIdCarrier: this.assetIdCarrier,
          apiKeyCarrier: this.apiKeyCarrier,
        });
      } else {
        for (let index in this.devices_sn) {
          devices.push({
            serialNumber: this.devices_sn[index],
            carplate: this.carplate[index],
            assetIdCarrier: this.assetIdCarrier,
            apiKeyCarrier: this.apiKeyCarrier,
          });
        }
      }
      const getTimeZoneOffset = () => {
        let t = new Date().toString().match(/[-\+]\d{4}/)[0];
        return t.substring(0, 3) + ':' + t.substr(3);
      };

      try {
        const dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();
        const payload = {
          devices,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          startingTime: this.startingTime,
          endingTime: this.endingTime,
          get_mileage: this.km_count,
          get_working_time: this.driving_hours,
          get_map_route: this.map_route,
          get_trip_pois: this.pi_sum,
          get_pois_delivery: this.poi_delivery,
          get_fridge_engine_hours: this.engine_hours,
          get_temperature_graph: this.temp_graph,
          get_humidity_graph: this.humidity_graph,
          get_journey_summary: this.journey_sum,
          get_temperature_report: this.temp_ticket,
          get_humidity_report: this.humidity_report,
          get_open_doors: this.open_doors,
          get_custom: this.custom,
          get_cold_machine: this.cold_machine,
          timeZoneOffset: getTimeZoneOffset(),
          timezone: new Date().getTimezoneOffset() / 60,
          email: user.email,
          probes: this.probes,
          dateTimeFormat: dateTimeFormat,
        };
        await getReport(token, payload);
      } catch (e) {
        switch (e.code) {
          case GetReportException.unknownError:
          case GetReportException.incorrectParameters:
          case GetReportException.invalidToken:
          case GetReportException.expiredToken:
            console.error(e);
            this.errorText = this.$t('general.error.unexpected_error');
            this.errorDialog = true;
            break;
          default:
            console.error('CATCH ERROR:', e);
            this.invalidReport = true;
        }
        return;
      }
      this.validReport = true;
    }
  },
};
</script>
